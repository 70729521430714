<div class="row">
  <nav class="col-12 mb-3 navbar-fixed-top section">
    <div
      ngbRadioGroup
      name="radioBasic"
      [(ngModel)]="modelRadio"
      class="w-100 h-100 d-flex justify-content-start nav-scroll"
    >
      <a
        (click)="scrollToSection('products-section')"
        *ngIf="products.length > 0"
      >
        <label
          ngbButtonLabel
          class="btn round labelLink text-bold-700"
          [ngStyle]="{
            'background-color': modelRadio === 'products' ? selectedColor : '',
            color:
              modelRadio === 'products'
                ? calculateTextColor(selectedColor)
                : '#757575'
          }"
        >
          <input
            ngbButton
            type="radio"
            value="products"
            class="checkbox-ghost"
          />
          Produtos
        </label>
      </a>

      <a
        (click)="scrollToSection('subscriptions-section')"
        *ngIf="subscriptions.length > 0"
      >
        <label
          ngbButtonLabel
          class="btn round labelLink text-bold-700"
          [ngStyle]="{
            'background-color':
              modelRadio === 'subscriptions' ? selectedColor : '',
            color:
              modelRadio === 'subscriptions'
                ? calculateTextColor(selectedColor)
                : '#757575'
          }"
        >
          <input
            ngbButton
            type="radio"
            value="subscriptions"
            class="checkbox-ghost"
          />
          Assinaturas
        </label>
      </a>

      <a (click)="scrollToSection('contact-section')">
        <label
          ngbButtonLabel
          class="btn round labelLink text-bold-700"
          [ngStyle]="{
            'background-color': modelRadio === 'contact' ? selectedColor : '',
            color:
              modelRadio === 'contact'
                ? calculateTextColor(selectedColor)
                : '#757575'
          }"
        >
          <input
            ngbButton
            type="radio"
            value="contact"
            class="checkbox-ghost"
          />
          Contato
        </label>
      </a>
    </div>
  </nav>

  <div class="col-12 mb-3" *ngIf="products.length > 0 || subscriptions">
    <div id="all-section" class="w-100 d-flex flex-column">
      <div class="verTodos cols-12">
        <span class="font-size-9 text-bold-700">Todos</span>
        <a
          routerLink="all"
          routerLinkActive="selected"
          [routerLinkActiveOptions]="{ exact: true }"
          class="grey darken-1 shadow-none"
        >
          Ver Todos
          <i class="fa fa-chevron-right ml-1"></i>
        </a>
      </div>
      <app-marketplace-all-slider
        [products]="products"
        [subscription]="subscriptions"
        [selectedColor]="selectedColor"
      >
      </app-marketplace-all-slider>
    </div>
  </div>

  <div class="col-12 mb-3" *ngIf="products.length > 0">
    <div id="products-section" class="w-100 d-flex flex-column">
      <div class="verTodos cols-12">
        <span class="font-size-9 text-bold-700">Produtos</span>
        <a
          routerLink="products"
          routerLinkActive="selected"
          [routerLinkActiveOptions]="{ exact: true }"
          class="grey darken-1 shadow-none"
        >
          Ver Todos
          <i class="fa fa-chevron-right ml-1"></i>
        </a>
      </div>
      <app-marketplace-products-slider
        [products]="products"
        [selectedColor]="selectedColor"
      >
      </app-marketplace-products-slider>
    </div>
  </div>

  <div class="col-12 mb-3" *ngIf="subscriptions.length > 0">
    <section id="subscriptions-section" class="w-100 d-flex flex-column">
      <div class="verTodos cols-12">
        <span class="font-size-9 text-bold-700">Assinaturas</span>
        <a
          routerLink="subscriptions"
          routerLinkActive="selected"
          [routerLinkActiveOptions]="{ exact: true }"
          class="grey darken-1 shadow-none"
        >
          Ver Todos
          <i class="fa fa-chevron-right ml-1"></i>
        </a>
      </div>
      <app-marketplace-subscription-slides
        [subscription]="subscriptions"
        [selectedColor]="selectedColor"
      ></app-marketplace-subscription-slides>
    </section>
  </div>

  <div class="col-12 mb-3">
    <section id="contact-section" class="w-100 d-flex flex-column">
      <div class="d-flex mb-2">
        <span class="text-bold-700">Contato</span>
      </div>
      <div>
        <div class="card rounded-lg overflow-hidden m-0">
          <div
            class="card-body p-0"
            style="border-radius: 8px; border: 1px solid rgb(225, 213, 213)"
          >
            <button
              *ngFor="let contact of seller.SellerStoreContact; let i = index"
              type="button"
              (click)="openContact(contact)"
              class="btn shadow-none w-100 d-flex align-items-center justify-content-between px-4 py-3 rounded-0 border-top-0 border-left-0 border-right-0"
              [ngClass]="{
                'border-bottom border-light-grey':
                  i !== seller.SellerStoreContact.length - 1
              }"
            >
              <div class="d-flex align-items-center">
                <i
                  class="mr-3"
                  [class]="getIconClass(contact.contactTypeId)"
                  [ngStyle]="{ color: selectedColor }"
                ></i>
                <span class="text-bold-500 mr-2">
                  {{ getContactTypeLabel(contact.contactTypeId) }}:
                </span>
                <span class="text-bold-500 grey">{{ contact.contact }}</span>
              </div>
              <i
                class="fa fa-chevron-right ml-2"
                [style.color]="selectedColor"
              ></i>
            </button>
          </div>
        </div>
      </div>
    </section>
  </div>

  <ngx-spinner></ngx-spinner>
</div>
