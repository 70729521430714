import { Component, HostListener, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import Api from "app/helpers/api";
import { NgxSpinnerService } from "ngx-spinner";
import { Location } from "@angular/common";
@Component({
  selector: "app-personal-marketplace-subscriptions",
  templateUrl: "./personal-marketplace-subscriptions.component.html",
  styleUrls: ["./personal-marketplace-subscriptions.component.scss"],
})
export class PersonalMarketplaceSubscriptionsComponent implements OnInit {
  subscriptions: any[] = [];
  page = 1;
  pageSize = 5;
  totalItems: number = 0;
  apiCall = this.api.new();
  isMobile: boolean = false;
  paginatedSubscriptions: any[] = [];
  expandedSubscriptions: Set<number> = new Set();
  seller: any = [];
  selectedColor: string;
  slug: string;

  constructor(
    private api: Api,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    this.route.parent?.paramMap.subscribe((params) => {
      this.slug = params.get("slug");
    });

    this.sellerInfo();
    this.isMobile = window.innerWidth <= 768;
    window.onresize = () => {
      this.isMobile = window.innerWidth <= 768;
    };
  }

  @HostListener("window:resize", ["$event"])
  checkIfMobile(event?: Event) {
    3;
    this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    this.isMobile = window.innerWidth <= 768;
    this.spinner.hide();
    console.log(this.isMobile);
  }
  toggleDetails(subscription: any) {
    if (this.expandedSubscriptions.has(subscription.id)) {
      this.expandedSubscriptions.delete(subscription.id);
    } else {
      this.expandedSubscriptions.add(subscription.id);
    }
  }

  isExpanded(subscription: any): boolean {
    return this.expandedSubscriptions.has(subscription.id);
  }

  sellerInfo() {
    this.apiCall.get(`/seller-store/slug/${this.slug}`).subscribe(
      (data) => {
        this.seller = data.return;
        this.selectedColor = this.seller.baseColor;

        this.loadMarketplaceData();
        this.spinner.hide();
      },
      (error) => {
        console.log("Erro ao obter informações da loja:", error);
      }
    );
  }

  calculateTextColor(bgColor: string): string {
    if (bgColor) {
      const color = bgColor.replace("#", "");

      const fullColor =
        color.length === 3
          ? color
              .split("")
              .map((c) => c + c)
              .join("")
          : color;

      const r = parseInt(fullColor.substring(0, 2), 16);
      const g = parseInt(fullColor.substring(2, 4), 16);
      const b = parseInt(fullColor.substring(4, 6), 16);

      const referenceR = parseInt("ff", 16);
      const referenceG = parseInt("66", 16);
      const referenceB = parseInt("00", 16);

      if (
        r < referenceR ||
        (r === referenceR && g < referenceG) ||
        (r === referenceR && g === referenceG && b < referenceB)
      ) {
        return "white";
      }

      return "grey";
    }
  }

  loadMarketplaceData() {
    const offset = 0;
    const limit = 10;
    const sellerStoreId = this.seller.id;

    const url = `marketplace/customer/view?type=1&offset=${offset}&limit=${limit}&sellerStoreId=${sellerStoreId}`;

    this.apiCall.get(url).subscribe((response) => {
      if (response.code === 200 && !response.error) {
        this.subscriptions = response.return.rows;
        console.log("subscriptions:", this.subscriptions);
        this.totalItems = this.subscriptions.length;
        this.updatePaginatedSubscriptions();
        this.spinner.hide();
      } else {
        console.error("Erro ao buscar dados:", response.message);
        this.spinner.hide();
      }
    });
  }

  updatePaginatedSubscriptions() {
    const startIndex = (this.page - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.paginatedSubscriptions = this.subscriptions.slice(
      startIndex,
      endIndex
    );
  }

  onPageChange(page: number) {
    this.page = page;
    this.updatePaginatedSubscriptions();
  }

  buySubscription(subscription) {
    window.open(subscription, "_blank");
  }

  goBack() {
    this.location.back();
  }

  integerToReal(val: number) {
    return Intl.NumberFormat("pt-Br", {
      style: "currency",
      currency: "BRL",
    }).format(val / 100);
  }

  getIntervalTypeInPortuguese(intervalType: string): string {
    const translations: { [key: string]: string } = {
      day: "Dia",
      week: "Semana",
      months: "Mês",
      year: "Ano",
    };

    return translations[intervalType] || intervalType;
  }
}
