import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import Api from "app/helpers/api";
import { NgxSpinnerService } from "ngx-spinner";
import { BehaviorSubject } from "rxjs";
import { take } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import { sellerStore } from "./store/stories.types";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ShareSiteComponent } from "app/modules/share-site/share-site.component";
import { TutoriaisComponent } from "app/modules/finance-store/tutoriais/tutoriais.component";
type RouteType = "store" | "services-and-products";

@Component({
  selector: "app-finance-store",
  templateUrl: "./finance-store.component.html",
  styleUrls: ["./finance-store.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class FinanceStoreComponent implements OnInit {
  apiCall = this.api.new().silent();
  route: string = "store";
  activeRoute: RouteType = "store";
  account: boolean = false;
  seller: sellerStore;
  $isLoading = new BehaviorSubject<boolean>(false);
  hasFeedback = false;
  feedback: "negative" | "neutral" | "positive" | null = null;
  feedbackNote: string = "";
  feedbackLoading: boolean = false;
  constructor(
    private router: Router,
    private api: Api,
    private modal: NgbModal,
    private toastr: ToastrService,
    private ref: ChangeDetectorRef,
    private spinner: NgxSpinnerService
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        let paths = event.url.split("/");
        let route = paths[paths.length - 1];

        if (route.includes("?")) {
          const newRoute = route.split("?")[0];
          route = newRoute;
        }

        this.activeRoute = route as RouteType;
      }
    });
  }

  async ngOnInit() {
    let localAccount = null;
    const subaccount = await this.apiCall
      .get("seller/me/marketplaceaccountlocal")
      .pipe(take(1))
      .toPromise();

    if (!subaccount.return) {
      this.account = true;
    } else {
      localAccount = subaccount.return;
    }
    this.userInfo();
  }
  userInfo(): void {
    this.apiCall.get("user/seller-store").subscribe(
      (data) => {
        this.seller = data.return;
        console.log(this.seller);
      },
      (error) => {
        this.spinner.hide();
        console.log("Erro ao obter informações da loja:", error);
      }
    );
  }
  sendFeedback() {
    if (!this.feedback || this.feedbackLoading) return;

    this.feedbackLoading = true;
    this.apiCall
      .post("feature/feedback", {
        feature: "seller-store",
        rating:
          this.feedback === "positive"
            ? 3
            : this.feedback === "neutral"
            ? 2
            : 1,
        note: this.feedbackNote,
      })
      .subscribe((response) => {
        this.feedbackLoading = false;

        if (response.error) {
          this.toastr.error(
            "Ocorreu um erro ao enviar o feedback, tente novamente."
          );
        } else {
          this.toastr.success("Feedback enviado com sucesso.");
          this.hasFeedback = true;
          this.ref.detectChanges();
        }
      });
  }

  setFeedback(feedback: "negative" | "neutral" | "positive") {
    this.feedback = feedback;
  }
  openShareModal() {
    const modalRef = this.modal.open(ShareSiteComponent);
    modalRef.componentInstance.siteUrl = `https://app.opersonaldigital.com.br/pages/loja/${this.seller.slug}`;
    modalRef.componentInstance.whatsappUrl = `https://wa.me/?text=${encodeURIComponent(
      `https://app.opersonaldigital.com.br/pages/loja/${this.seller.slug}`
    )}`;
  }
  openTutoriaisModal(size?: string) {
    const modalRef = this.modal.open(TutoriaisComponent, {
      centered: true,
      size: size || "xl",
    });
  }
}
