import { Component, Input, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import Api from "app/helpers/api";
import { ProductType } from "app/personal/pages/finance-store/services-and-products/services-and-products.types";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { take } from "rxjs/operators";
import Swal from "sweetalert2";
import * as CryptoJS from "crypto-js";
import { Router } from "@angular/router";
import { ImageUploadModalComponent } from "../image-upload-modal/image-upload-modal.component";

@Component({
  selector: "app-product-creation-and-edition-modal",
  templateUrl: "./product-creation-and-edition-modal.component.html",
  styleUrls: ["./product-creation-and-edition-modal.component.scss"],
})
export class ProductCreationAndEditionModalComponent implements OnInit {
  apiCall = this.api.new().silent();

  newProduct = {
    id: null,
    name: null,
    plan_type: null,
    payable_with: null,
    description: null,
    identifier: null,
    product_image: null,
    value_cents: null,
    delivery_video: null,
    delivery_logo: null,
    delivery_image: null,
    delivery_instructions: null,
    delivery_link: null,
    checkout_url: null,
    status: null,
    towAccess: null,
  };
  account = null;
  helperType: number | null = null;
  helperImage: string | null = null;

  url_video: File | string | null = null;
  sha1_video: File | string | null = null;
  safeURL: SafeResourceUrl | null = null;
  video_file: File | string | null = null;

  product_image: File | string | null = null;
  product_image_url: File | any | null = null;
  product_image_sha1: File | string | null = null;

  delivery_logo: File | string | null = null;
  delivery_logo_url: File | string | null = null;
  delivery_logo_sha1: File | string | null = null;

  delivery_image: File | string | null = null;
  delivery_image_url: File | string | null = null;
  delivery_image_sha1: File | string | null = null;

  newInvoice = {
    payable_with: [],
    items: [],
    due_date: null,
    payer: {
      email: null,
      name: null,
      cpf_cnpj: null,
      zip_code: null,
      street: null,
      city: null,
      state: null,
      number: null,
    },
  };
  newSubscribe = {
    subitems: [],
    suspend_on_invoice_expired: true,
    plan_identifier: null,
    expires_at: null,
    customer: {
      email: null,
      name: null,
      cpf_cnpj: null,
      zip_code: null,
      street: null,
      city: null,
      state: null,
      number: null,
      district: null,
    },
  };
  productsPlans = {
    monthly: {
      id: null,
      name: null,
      identifier: null,
      status: true,
      value_cents: null,
      max_cycles: null,
      interval: 1,
      interval_type: "months",
      iugu_id: null,
    },
    quarterly: {
      id: null,
      name: null,
      identifier: null,
      status: false,
      value_cents: null,
      interval: 3,
      interval_type: "months",
      iugu_id: null,
    },
    semiannual: {
      id: null,
      name: null,
      identifier: null,
      status: false,
      value_cents: null,
      interval: 6,
      interval_type: "months",
      iugu_id: null,
    },
    annual: {
      id: null,
      name: null,
      identifier: null,
      status: false,
      value_cents: null,
      interval: 12,
      interval_type: "months",
      iugu_id: null,
    },
  };
  tiposPagamento = [];
  tiposPlanos = [
    {
      id: "0",
      label: "Compra única",
    },
    {
      id: "1",
      label: "Plano de Recorrência",
    },
  ];
  tiposIntervalo = [
    {
      id: "months",
      label: "Mês",
    },
    {
      id: "weeks",
      label: "Semana",
    },
  ];

  tipoPagamentoEscolhido = [];
  isAtrelarAluno = false;

  @Input("defaultProduct") defaultProduct: ProductType | null = null;
  @Input("callback") callback: ((status: "success" | "error") => void) | null =
    null;

  constructor(
    private activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private router: Router,
    private _sanitizer: DomSanitizer,
    private toastr: ToastrService,
    private api: Api
  ) {}

  async ngOnInit() {
    const product = this.defaultProduct || this.newProduct;
    this.newProduct = {
      id: product.id,
      name: product.name,
      payable_with: product.payable_with,
      plan_type:
        this.tiposPlanos.find((p) => p.id == product.plan_type) || null,
      description: product.description,
      identifier: product.identifier,
      value_cents: product.value_cents
        ? (product.value_cents / 100).toFixed(2).toString().replace(".", ",")
        : null,
      product_image: product.product_image,
      delivery_video: product.delivery_video,
      delivery_logo: product.delivery_logo,
      delivery_image: product.delivery_image,
      delivery_instructions: product.delivery_instructions,
      delivery_link: product.delivery_link,
      checkout_url: product.checkout_url,
      status: product.status,
      towAccess: product.towAccess,
    };
    if (product.plan_type == 1) {
      this.defaultProduct.Plans.map((prod) => {
        this.productsPlans.monthly.value_cents = (prod.value_cents / 100)
          .toFixed(2)
          .toString()
          .replace(".", ",");
      });
    }
    if (product.payable_with) {
      this.tipoPagamentoEscolhido = JSON.parse(product.payable_with);
    }

    if (this.newProduct.product_image)
      this.product_image_url = this.newProduct.product_image;
    if (this.newProduct.delivery_image)
      this.delivery_image_url = this.newProduct.delivery_image;
    if (this.newProduct.delivery_video) {
      if (
        this.newProduct.delivery_video.startsWith(
          "https://www.youtube.com/watch?"
        ) ||
        this.newProduct.delivery_video.startsWith(
          "http://www.youtube.com/watch?"
        )
      ) {
        this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(
          this.newProduct.delivery_video.replace(
            "https://www.youtube.com/watch?v=",
            "https://www.youtube.com/embed/"
          )
        );
      }
      this.url_video = this.newProduct.delivery_video;
    }

    if (this.newProduct.delivery_logo) {
      this.delivery_logo_url = this.newProduct.delivery_logo;
    }

    const subaccount = await this.apiCall
      .get("seller/me/marketplaceaccountlocal")
      .pipe(take(1))
      .toPromise();

    if (subaccount.return) {
      this.account = subaccount.return;
    }

    this.resetPaymentMethods();
  }

  async fileToBase64(file: File): Promise<string | ArrayBuffer> {
    return await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  async verifyDuration(file: File) {
    return await new Promise<boolean>((res) => {
      var video = document.createElement("video");
      video.preload = "metadata";
      video.src = URL.createObjectURL(file);
      video.onloadedmetadata = function () {
        window.URL.revokeObjectURL(video.src);
        var duration = video.duration;
        if (duration > 31) {
          res(false);
        } else {
          res(true);
        }
      };
    });
  }

  async selecionarImagemProduto() {
    const self = this;
    let { value: file } = await Swal.fire({
      title: "Imagem do Produto",
      input: "file",
      showCancelButton: true,
      cancelButtonText: `Cancelar`,
      confirmButtonText: `Salvar`,
      inputAttributes: {
        accept: "image/*",
        "aria-label": "Faça upload de uma imagem.",
      },
      inputValidator: async (value) => {
        if (!value) {
          return "Você precisa escolher uma imagem!";
        }
        // @ts-ignore
        let file: File = value;

        if (file.size > 30000000) {
          return "São permitidos imagens de até 30MB!";
        }
        if (
          file.type != "image/png" &&
          file.type != "image/jpg" &&
          file.type != "image/jpeg"
        ) {
          return "São permitidos apenas imagens!";
        }
        self.product_image = file;
        const base64 = await self.fileToBase64(file);
        if (base64) self.product_image_url = base64;

        var reader = new FileReader(); //define a Reader
        reader.onload = function (f) {
          var file_result = this.result; // this == reader, get the loaded file "result"
          var file_wordArr = CryptoJS.lib.WordArray.create(
            file_result as ArrayBuffer
          ); //convert blob to WordArray , see https://code.google.com/p/crypto-js/issues/detail?id=67
          var sha1_hash = CryptoJS.SHA1(file_wordArr); //calculate SHA1 hash
          self.product_image_sha1 = sha1_hash.toString();
        };
        reader.readAsArrayBuffer(file); //read file as ArrayBuffer
      },
    });
  }

  // uploadImagemProduto(image: File): void {
  //   const reader = new FileReader();

  //   reader.onload = (e: any) => {
  //     this.product_image_url = e.target.result;
  //   };

  //   reader.readAsDataURL(image);
  // }

  async selecionarImagemLogoObrigado() {
    const self = this;

    let { value: file } = await Swal.fire({
      title: "Imagem do Produto",
      input: "file",
      showCancelButton: true,
      cancelButtonText: `Cancelar`,
      confirmButtonText: `Salvar`,
      inputAttributes: {
        accept: "image/*",
        "aria-label": "Faça upload de uma imagem.",
      },
      inputValidator: async (value) => {
        if (!value) {
          return "Você precisa escolher uma imagem!";
        }
        // @ts-ignore
        let file: File = value;

        if (file.size > 30000000) {
          return "São permitidos imagens de até 30MB!";
        }
        if (
          file.type != "image/png" &&
          file.type != "image/jpg" &&
          file.type != "image/jpeg"
        ) {
          return "São permitidos apenas imagens!";
        }
        this.delivery_logo = file;
        const base64 = await this.fileToBase64(file);
        if (base64) this.delivery_logo_url = base64 as string;

        var reader = new FileReader(); //define a Reader
        reader.onload = function (f) {
          var file_result = this.result; // this == reader, get the loaded file "result"
          var file_wordArr = CryptoJS.lib.WordArray.create(
            file_result as ArrayBuffer
          ); //convert blob to WordArray , see https://code.google.com/p/crypto-js/issues/detail?id=67
          var sha1_hash = CryptoJS.SHA1(file_wordArr); //calculate SHA1 hash
          self.delivery_logo_sha1 = sha1_hash.toString();
        };
        reader.readAsArrayBuffer(file); //read file as ArrayBuffer

        return null;
      },
    });
  }

  checkFormasPagamento() {
    if (this.tipoPagamentoEscolhido.length == 0) {
      this.resetPaymentMethods();
    } else {
      if (this.tipoPagamentoEscolhido.filter((x) => x.id == "all").length > 0) {
        this.tipoPagamentoEscolhido = [
          {
            id: "all",
            label: "Todas as Formas",
          },
        ];

        this.tiposPagamento = [
          {
            id: "all",
            label: "Todas as Formas",
          },
        ];
      }
    }
  }

  resetPaymentMethods() {
    this.tiposPagamento = [];

    if (this.account.creditcard_payment) {
      this.tiposPagamento.push({
        id: "credit_card",
        label: "Cartão de Crédito",
      });
    }

    if (this.account.bankslip_payment) {
      this.tiposPagamento.push({
        id: "bank_slip",
        label: "Boleto",
      });
    }

    if (this.account.pix_payment) {
      this.tiposPagamento.push({
        id: "pix",
        label: "PIX",
      });
    }

    if (this.tiposPagamento.length == 3) {
      this.tiposPagamento.push({
        id: "all",
        label: "Todas as formas",
      });
    }
  }

  async toggleVideoOrGif(isVideo: boolean, isUrl: boolean) {
    const self = this;

    if (isVideo) {
      if (isUrl) {
        let { value: file } = await Swal.fire({
          title: "Url do Vídeo do YouTube",
          input: "text",
          showCancelButton: true,
          cancelButtonText: `Cancelar`,
          confirmButtonText: `Salvar`,
          inputValidator: async (value) => {
            if (value && value.startsWith("https://youtu.be/")) {
              value = value.replace(
                "https://youtu.be/",
                "https://www.youtube.com/watch?v="
              );
            }
            if (!value) {
              return "Você precisa informar a url do YouTube!";
            }
            if (
              !value.startsWith("https://www.youtube.com/watch?") &&
              !value.startsWith("http://www.youtube.com/watch?")
            ) {
              return 'Você precisa informar uma url válida do Youtube! esta url deve começar com "https://www.youtube.com/watch?"';
            }
            this.url_video = value;
            if (value.startsWith("https://www.youtube.com/watch?")) {
              this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(
                this.url_video.replace(
                  "https://www.youtube.com/watch?v=",
                  "https://www.youtube.com/embed/"
                )
              );
            } else {
              this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(
                this.url_video.replace(
                  "http://www.youtube.com/watch?v=",
                  "https://www.youtube.com/embed/"
                )
              );
            }
            this.video_file = null;

            return null;
          },
        });
      } else {
        let { value: file } = await Swal.fire({
          title: "Vídeo Página de entrega",
          html: '<small style="color:red">O Tamanho máximo do arquivo é de 30mb</small>',
          input: "file",
          showCancelButton: true,
          cancelButtonText: `Cancelar`,
          confirmButtonText: `Salvar`,
          inputAttributes: {
            accept: "video/mp4",
            "aria-label": "Faça upload de um vídeo de até 30 segundos.",
          },
          inputValidator: async (value) => {
            if (!value) {
              return "Você precisa escolher um vídeo!";
            }
            // @ts-ignore
            let file: File = value;

            if (file.size > 30000000) {
              return "São permitidos vídeos de até 30MB!";
            }

            const isValid = await this.verifyDuration(file);
            if (!isValid) {
              return "São permitidos vídeos de até 30 segundos!";
            }
            this.video_file = file;
            const base64 = await this.fileToBase64(file);
            if (base64) this.url_video = base64 as string;
            var reader = new FileReader(); //define a Reader
            reader.onload = function (f) {
              var file_result = this.result; // this == reader, get the loaded file "result"
              var file_wordArr = CryptoJS.lib.WordArray.create(
                file_result as ArrayBuffer
              ); //convert blob to WordArray , see https://code.google.com/p/crypto-js/issues/detail?id=67
              var sha1_hash = CryptoJS.SHA1(file_wordArr); //calculate SHA1 hash
              self.sha1_video = sha1_hash.toString();
            };
            reader.readAsArrayBuffer(file); //read file as ArrayBuffer

            return null;
          },
        });
      }
    } else {
      let { value: file } = await Swal.fire({
        title: "Imagem da página de entrega",
        input: "file",
        showCancelButton: true,
        cancelButtonText: `Cancelar`,
        confirmButtonText: `Salvar`,
        inputAttributes: {
          accept: "image/*",
          "aria-label": "Faça upload de uma imagem.",
        },
        inputValidator: async (value) => {
          if (!value) {
            return "Você precisa escolher uma imagem!";
          }
          // @ts-ignore
          let file: File = value;

          if (file.size > 30000000) {
            return "São permitidos imagens de até 30MB!";
          }
          if (
            file.type != "image/png" &&
            file.type != "image/jpg" &&
            file.type != "image/jpeg"
          ) {
            return "São permitidos apenas imagens!";
          }
          this.delivery_image = file;
          const base64 = await this.fileToBase64(file);
          if (base64) this.delivery_image_url = base64 as string;

          var reader = new FileReader(); //define a Reader
          reader.onload = function (f) {
            var file_result = this.result; // this == reader, get the loaded file "result"
            var file_wordArr = CryptoJS.lib.WordArray.create(
              file_result as ArrayBuffer
            ); //convert blob to WordArray , see https://code.google.com/p/crypto-js/issues/detail?id=67
            var sha1_hash = CryptoJS.SHA1(file_wordArr); //calculate SHA1 hash
            self.delivery_image_sha1 = sha1_hash.toString();
          };
          reader.readAsArrayBuffer(file); //read file as ArrayBuffer

          return null;
        },
      });
    }
  }

  async submitPlan() {
    const self = this;
    let planosProduto = [];

    await this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    if (self.product_image) {
      console.log("product_image", self.product_image);
      const dataUrl = await self.apiCall
        .get("blackbaze/urltoupload")
        .pipe(take(1))
        .toPromise();
      if (!dataUrl.return) {
        self.spinner.hide();
        return;
      }
      if (
        dataUrl.return &&
        dataUrl.return.authorizationToken &&
        dataUrl.return.uploadUrl &&
        dataUrl.return.uniqid
      ) {
        var optionsB2 = {
          headers: {
            Authorization: dataUrl.return.authorizationToken,
            "Content-Type": (this.product_image as File)?.type || "",
            "X-Bz-File-Name": dataUrl.return.uniqid.toString() + ".jpeg",
            "X-Bz-Content-Sha1": (this.product_image_sha1 as string) || "",
          },
        };
        const returnedUpload = await self.api.http
          .post(dataUrl.return.uploadUrl, this.product_image, optionsB2)
          .toPromise();
        if (returnedUpload)
          self.product_image_url =
            "https://files.wecodde.com/file/wecodde/" +
            JSON.parse(JSON.stringify(returnedUpload)).fileName;
      }
    }

    if (self.video_file) {
      const dataUrl = await self.apiCall
        .get("blackbaze/urltoupload")
        .pipe(take(1))
        .toPromise();
      if (!dataUrl.return) {
        self.spinner.hide();
        return;
      }
      if (
        dataUrl.return &&
        dataUrl.return.authorizationToken &&
        dataUrl.return.uploadUrl &&
        dataUrl.return.uniqid
      ) {
        var optionsB2 = {
          headers: {
            Authorization: dataUrl.return.authorizationToken,
            "Content-Type": (this.video_file as File)?.type || "",
            "X-Bz-File-Name": dataUrl.return.uniqid.toString() + ".mp4",
            "X-Bz-Content-Sha1": (this.sha1_video as string) || "",
          },
        };
        const returnedUpload = await self.api.http
          .post(dataUrl.return.uploadUrl, this.video_file, optionsB2)
          .toPromise();
        if (returnedUpload)
          self.url_video =
            "https://files.wecodde.com/file/wecodde/" +
            JSON.parse(JSON.stringify(returnedUpload)).fileName;
      }
    }

    if (self.delivery_image) {
      const dataUrl = await self.apiCall
        .get("blackbaze/urltoupload")
        .pipe(take(1))
        .toPromise();
      if (!dataUrl.return) {
        self.spinner.hide();
        return;
      }
      if (
        dataUrl.return &&
        dataUrl.return.authorizationToken &&
        dataUrl.return.uploadUrl &&
        dataUrl.return.uniqid
      ) {
        var optionsB2 = {
          headers: {
            Authorization: dataUrl.return.authorizationToken,
            "Content-Type": (this.delivery_image as File)?.type || "",
            "X-Bz-File-Name": dataUrl.return.uniqid.toString() + ".jpeg",
            "X-Bz-Content-Sha1": (this.delivery_image_sha1 as string) || "",
          },
        };
        const returnedUpload = await self.api.http
          .post(dataUrl.return.uploadUrl, this.delivery_image, optionsB2)
          .toPromise();
        if (returnedUpload)
          self.delivery_image_url =
            "https://files.wecodde.com/file/wecodde/" +
            JSON.parse(JSON.stringify(returnedUpload)).fileName;
      }
    }

    if (self.delivery_logo) {
      const dataUrl = await self.apiCall
        .get("blackbaze/urltoupload")
        .pipe(take(1))
        .toPromise();
      if (!dataUrl.return) {
        self.spinner.hide();
        return;
      }
      if (
        dataUrl.return &&
        dataUrl.return.authorizationToken &&
        dataUrl.return.uploadUrl &&
        dataUrl.return.uniqid
      ) {
        var optionsB2 = {
          headers: {
            Authorization: dataUrl.return.authorizationToken,
            "Content-Type": (this.delivery_logo as File)?.type || "",
            "X-Bz-File-Name": dataUrl.return.uniqid.toString() + ".jpeg",
            "X-Bz-Content-Sha1": (this.delivery_logo_sha1 as string) || "",
          },
        };
        const returnedUpload = await self.api.http
          .post(dataUrl.return.uploadUrl, this.delivery_logo, optionsB2)
          .toPromise();
        if (returnedUpload)
          self.delivery_logo_url =
            "https://files.wecodde.com/file/wecodde/" +
            JSON.parse(JSON.stringify(returnedUpload)).fileName;
      }
    }

    let productToSend = { ...this.newProduct };
    productToSend.payable_with = JSON.stringify(this.tipoPagamentoEscolhido);
    productToSend.plan_type = productToSend.plan_type.id == 0 ? 0 : 1;
    productToSend.identifier = ("plan_" + this.makeid(4)).toLowerCase();
    productToSend.value_cents = (productToSend.value_cents * 100).toFixed(0);
    productToSend.product_image = self.product_image_url
      ? self.product_image_url
      : null;
    productToSend.delivery_video = self.url_video ? self.url_video : null;
    productToSend.delivery_image = self.delivery_image_url
      ? self.delivery_image_url
      : null;
    productToSend.delivery_logo = self.delivery_logo_url
      ? self.delivery_logo_url
      : null;

    if (productToSend.plan_type == 1) {
      if (this.productsPlans.monthly.status == true) {
        this.productsPlans.monthly.name = productToSend.name + " mensal";
        this.productsPlans.monthly.identifier = (
          "planrec_" + this.makeid(4)
        ).toLowerCase();
        this.productsPlans.monthly.value_cents = (
          this.productsPlans.monthly.value_cents * 100
        ).toFixed(0);
        planosProduto.push(this.productsPlans.monthly);
      }

      if (this.productsPlans.quarterly.status == true) {
        this.productsPlans.quarterly.name = productToSend.name + " trimestral";
        this.productsPlans.quarterly.identifier = (
          "planrec_" + this.makeid(4)
        ).toLowerCase();
        this.productsPlans.quarterly.value_cents = (
          this.productsPlans.quarterly.value_cents * 100
        ).toFixed(0);
        planosProduto.push(this.productsPlans.quarterly);
      }

      if (this.productsPlans.semiannual.status == true) {
        this.productsPlans.semiannual.name = productToSend.name + " semestral";
        this.productsPlans.semiannual.identifier = (
          "planrec_" + this.makeid(4)
        ).toLowerCase();
        this.productsPlans.semiannual.value_cents = (
          this.productsPlans.semiannual.value_cents * 100
        ).toFixed(0);
        planosProduto.push(this.productsPlans.semiannual);
      }

      if (this.productsPlans.annual.status == true) {
        this.productsPlans.annual.name = productToSend.name + " anual";
        this.productsPlans.annual.identifier = (
          "planrec_" + this.makeid(4)
        ).toLowerCase();
        this.productsPlans.annual.value_cents = (
          this.productsPlans.annual.value_cents * 100
        ).toFixed(0);
        planosProduto.push(this.productsPlans.annual);
      }

      productToSend.towAccess = self.isAtrelarAluno;
    }
    const submit = await this.apiCall
      .post("seller/me/subconta/planos", {
        product: productToSend,
        plans: planosProduto,
      })
      .pipe(take(1))
      .toPromise();
    await this.spinner.hide();
    if (submit.success) {
      this.closeModal();
      this.toastr.success("Plano criado com sucesso!", "Tudo certo!");
      this.callback?.("success");
      setTimeout(() => {
        window.location.reload();
      }, 1200);
    } else {
      this.callback?.("error");
      this.toastr.error(submit.message, "Ops :(");
    }
  }

  async submitUpdatePlan() {
    const self = this;
    let planosProduto = [];

    await this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    if (self.product_image) {
      const dataUrl = await self.apiCall
        .get("blackbaze/urltoupload")
        .pipe(take(1))
        .toPromise();
      if (!dataUrl.return) {
        self.spinner.hide();
        return;
      }
      if (
        dataUrl.return &&
        dataUrl.return.authorizationToken &&
        dataUrl.return.uploadUrl &&
        dataUrl.return.uniqid
      ) {
        var optionsB2 = {
          headers: {
            Authorization: dataUrl.return.authorizationToken,
            "Content-Type": (this.product_image as File)?.type || "",
            "X-Bz-File-Name": dataUrl.return.uniqid.toString() + ".jpeg",
            "X-Bz-Content-Sha1": (this.product_image_sha1 as string) || "",
          },
        };
        const returnedUpload = await self.api.http
          .post(dataUrl.return.uploadUrl, this.product_image, optionsB2)
          .toPromise();
        if (returnedUpload)
          self.product_image_url =
            "https://files.wecodde.com/file/wecodde/" +
            JSON.parse(JSON.stringify(returnedUpload)).fileName;
      }
    }

    if (self.video_file) {
      const dataUrl = await self.apiCall
        .get("blackbaze/urltoupload")
        .pipe(take(1))
        .toPromise();
      if (!dataUrl.return) {
        self.spinner.hide();
        return;
      }
      if (
        dataUrl.return &&
        dataUrl.return.authorizationToken &&
        dataUrl.return.uploadUrl &&
        dataUrl.return.uniqid
      ) {
        var optionsB2 = {
          headers: {
            Authorization: dataUrl.return.authorizationToken,
            "Content-Type": (this.video_file as File)?.type || "",
            "X-Bz-File-Name": dataUrl.return.uniqid.toString() + ".mp4",
            "X-Bz-Content-Sha1": (this.sha1_video as string) || "",
          },
        };
        const returnedUpload = await self.api.http
          .post(dataUrl.return.uploadUrl, this.video_file, optionsB2)
          .toPromise();
        if (returnedUpload)
          self.url_video =
            "https://files.wecodde.com/file/wecodde/" +
            JSON.parse(JSON.stringify(returnedUpload)).fileName;
      }
    }

    if (self.delivery_image) {
      const dataUrl = await self.apiCall
        .get("blackbaze/urltoupload")
        .pipe(take(1))
        .toPromise();
      if (!dataUrl.return) {
        self.spinner.hide();
        return;
      }
      if (
        dataUrl.return &&
        dataUrl.return.authorizationToken &&
        dataUrl.return.uploadUrl &&
        dataUrl.return.uniqid
      ) {
        var optionsB2 = {
          headers: {
            Authorization: dataUrl.return.authorizationToken,
            "Content-Type": (this.delivery_image as File)?.type || "",
            "X-Bz-File-Name": dataUrl.return.uniqid.toString() + ".jpeg",
            "X-Bz-Content-Sha1": (this.delivery_image_sha1 as string) || "",
          },
        };
        const returnedUpload = await self.api.http
          .post(dataUrl.return.uploadUrl, this.delivery_image, optionsB2)
          .toPromise();
        if (returnedUpload)
          self.delivery_image_url =
            "https://files.wecodde.com/file/wecodde/" +
            JSON.parse(JSON.stringify(returnedUpload)).fileName;
      }
    }

    if (self.delivery_logo) {
      const dataUrl = await self.apiCall
        .get("blackbaze/urltoupload")
        .pipe(take(1))
        .toPromise();
      if (!dataUrl.return) {
        self.spinner.hide();
        return;
      }
      if (
        dataUrl.return &&
        dataUrl.return.authorizationToken &&
        dataUrl.return.uploadUrl &&
        dataUrl.return.uniqid
      ) {
        var optionsB2 = {
          headers: {
            Authorization: dataUrl.return.authorizationToken,
            "Content-Type": (this.delivery_logo as File)?.type || "",
            "X-Bz-File-Name": dataUrl.return.uniqid.toString() + ".jpeg",
            "X-Bz-Content-Sha1": (this.delivery_logo_sha1 as string) || "",
          },
        };
        const returnedUpload = await self.api.http
          .post(dataUrl.return.uploadUrl, this.delivery_logo, optionsB2)
          .toPromise();
        if (returnedUpload)
          self.delivery_logo_url =
            "https://files.wecodde.com/file/wecodde/" +
            JSON.parse(JSON.stringify(returnedUpload)).fileName;
      }
    }

    let productToSend = { ...this.newProduct };
    productToSend.payable_with = JSON.stringify(this.tipoPagamentoEscolhido);
    productToSend.plan_type = productToSend.plan_type.id;
    productToSend.value_cents = productToSend.value_cents
      ? (+productToSend.value_cents.toString().replace(",", ".") * 100).toFixed(
          0
        )
      : null;
    productToSend.product_image = self.product_image_url
      ? self.product_image_url
      : null;
    productToSend.delivery_video = self.url_video ? self.url_video : null;
    productToSend.delivery_image = self.delivery_image_url
      ? self.delivery_image_url
      : null;
    productToSend.delivery_logo = self.delivery_logo_url
      ? self.delivery_logo_url
      : null;

    console.log("Plan type selected:", this.newProduct.plan_type);

    console.log("Plans to send:", planosProduto);

    if (productToSend.plan_type == 1) {
      if (
        this.productsPlans.monthly.status == true ||
        this.productsPlans.monthly.id != null
      ) {
        this.productsPlans.monthly.name = productToSend.name + " mensal";
        this.productsPlans.monthly.identifier = this.productsPlans.monthly.id
          ? this.productsPlans.monthly.identifier
          : ("planrec_" + this.makeid(4)).toLowerCase();
        this.productsPlans.monthly.value_cents = (
          this.productsPlans.monthly.value_cents.toString().replace(",", ".") *
          100
        ).toFixed(0);
        planosProduto.push(this.productsPlans.monthly);
      }

      if (
        this.productsPlans.quarterly.status == true ||
        this.productsPlans.quarterly.id != null
      ) {
        this.productsPlans.quarterly.name = productToSend.name + " trimestral";
        this.productsPlans.quarterly.identifier = this.productsPlans.quarterly
          .id
          ? this.productsPlans.quarterly.identifier
          : ("planrec_" + this.makeid(4)).toLowerCase();
        this.productsPlans.quarterly.value_cents = (
          this.productsPlans.quarterly.value_cents
            .toString()
            .replace(",", ".") * 100
        ).toFixed(0);
        planosProduto.push(this.productsPlans.quarterly);
      }
      if (
        this.productsPlans.semiannual.status == true ||
        this.productsPlans.semiannual.id != null
      ) {
        this.productsPlans.semiannual.name = productToSend.name + " semestral";
        this.productsPlans.semiannual.identifier = this.productsPlans.semiannual
          .id
          ? this.productsPlans.semiannual.identifier
          : ("planrec_" + this.makeid(4)).toLowerCase();
        this.productsPlans.semiannual.value_cents = (
          this.productsPlans.semiannual.value_cents
            .toString()
            .replace(",", ".") * 100
        ).toFixed(0);
        planosProduto.push(this.productsPlans.semiannual);
      }
      if (
        this.productsPlans.annual.status == true ||
        this.productsPlans.annual.id != null
      ) {
        this.productsPlans.annual.name = productToSend.name + " anual";
        this.productsPlans.annual.identifier = this.productsPlans.annual.id
          ? this.productsPlans.annual.identifier
          : ("planrec_" + this.makeid(4)).toLowerCase();
        this.productsPlans.annual.value_cents = (
          this.productsPlans.annual.value_cents.toString().replace(",", ".") *
          100
        ).toFixed(0);
        planosProduto.push(this.productsPlans.annual);
      }

      productToSend.towAccess = self.isAtrelarAluno;
    }
    console.log("product:", productToSend, "plans:", planosProduto);
    const submit: any = await this.apiCall
      .put("seller/me/subconta/planos", {
        product: productToSend,
        plans: planosProduto,
      })
      .pipe(take(1))
      .toPromise();
    await this.spinner.hide();

    if (submit.success) {
      this.toastr.success("Plano editado com sucesso!", "Tudo certo!");
      this.closeModal();
      this.callback?.("success");
    } else {
      this.callback?.("error");
      this.toastr.error(submit.message, "Ops :(");
    }
  }

  makeid(length: number) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  changeAtrelarAluno($event: any) {
    this.isAtrelarAluno = $event;
  }

  helperInfos(info: number, modal: any) {
    if (info == 1) {
      this.helperType = 1;
      this.helperImage =
        "https://files.wecodde.com/file/wecodde/tela_checkout.png";
    } else if (info == 2) {
      this.helperType = 2;
      this.helperImage =
        "https://files.wecodde.com/file/wecodde/OPD+P%C3%A1gina+Agradecimento+(1).jpg";
    }

    this.modalService.open(modal, { centered: true, size: "xl" });
  }

  closeModal() {
    this.activeModal.dismiss("closed-by-user");
  }
}
