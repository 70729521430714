<div class="row">
  <div class="col-12">
    <swiper [config]="swiperConfig" #swiperGallery class="swiper-gallery">
      <div class="swiper-wrapper">
        <div
          class="swiper-slide px-4"
          *ngFor="let subscriptions of subscriptionsMatrix"
        >
          <div
            *ngFor="let subscription of subscriptions; let i = index"
            class="d-flex flex-column bg-white rounded-lg border-grey shadow-sm subscription-card"
            [ngClass]="{
              'mr-2': !!subscriptions[i + 1],
              expanded: expandedDescriptions[i]
            }"
          >
            <div class="subscription-banner-container bg-grey bg-darken-2 mb-1">
              <img
                [src]="subscription.imgDesktop"
                alt="Banner da assinatura"
                class="w-100 subscription-banner"
              />
              <div
                class="destaque"
                [style.backgroundColor]="selectedColor"
                [style.color]="calculateTextColor(selectedColor)"
                *ngIf="subscription.isFeatured == true"
              >
                <p>Destaque</p>
              </div>
            </div>

            <div class="p-3 d-flex flex-column flex-grow-1">
              <span
                class="d-block font-size-9 text-bold-600 mb-2"
                [attr.title]="
                  subscription.title.length > 20 ? subscription.title : null
                "
              >
                {{ subscription.title | slice : 0 : 20
                }}{{ subscription.title.length > 20 ? "..." : "" }}
              </span>

              <div class="row ml-2">
                <span
                  class="d-block font-size-8 text-bold-600 mb-2"
                  [style.color]="selectedColor"
                >
                  {{
                    subscription.value_with_discount
                      ? integerToReal(subscription.value_with_discount)
                      : integerToReal(subscription.value)
                  }}/
                </span>
                <div
                  class="row align-items-center"
                  *ngIf="subscription.Plans[0].cycles != 0"
                >
                  <div class="col-auto">
                    <p class="subscription-interval">
                      {{
                        getIntervalTypeInPortuguese(
                          subscription.Plans[0].interval
                        )
                      }}
                    </p>
                  </div>
                  <div class="col-auto">
                    <p class="subscription-cycles p-1">
                      {{ subscription.Plans[0].cycles }} meses
                    </p>
                  </div>
                </div>
              </div>

              <div class="description-container">
                <p
                  class="mb-2 font-size-6 grey text-bold-500 description-preview"
                  [ngClass]="{ expanded: expandedDescriptions[i] }"
                >
                  {{
                    expandedDescriptions[i]
                      ? subscription.description
                      : getShortDescription(subscription.description)
                  }}
                </p>
                <button
                  class="btn btn-link p-0"
                  (click)="toggleDescription(i)"
                  [style.color]="selectedColor"
                >
                  {{
                    expandedDescriptions[i] ? "Mostrar menos" : "Mostrar mais"
                  }}
                </button>
              </div>

              <div class="mt-auto">
                <button
                  type="button"
                  (click)="handleBuyClick(subscription)"
                  class="w-100 btn rounded-sm"
                  [style.backgroundColor]="selectedColor"
                  [style.color]="calculateTextColor(selectedColor)"
                >
                  Comprar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        *ngIf="subscriptionsMatrix.length > 1"
        role="button"
        title="Anterior"
        [ngStyle]="{
          'background-color': 'white',
          'border-color': selectedColor,
          color: selectedColor
        }"
        class="swiper-button-prev btn btn-sm p-0 shadow-none round"
        (click)="goPrev()"
      ></div>

      <div
        *ngIf="subscriptionsMatrix.length > 1"
        role="button"
        title="Próximo"
        [ngStyle]="{
          'background-color': 'white',
          'border-color': selectedColor,
          color: selectedColor
        }"
        class="swiper-button-next btn btn-sm p-0 shadow-none round"
        (click)="goNext()"
      ></div>
    </swiper>

    <div class="w-100 d-flex mt-3">
      <div class="mx-auto d-flex">
        <button
          *ngFor="let p of subscriptionsMatrix; let i = index"
          type="button"
          (click)="goToIndex(i)"
          class="btn btn-sm p-1 m-0 round mr-2"
          [ngStyle]="{
            'background-color':
              swiperGallery?.swiper?.activeIndex === i ? selectedColor : '#ccc',
            color: calculateTextColor(
              swiperGallery?.swiper?.activeIndex === i ? selectedColor : '#ccc'
            )
          }"
        ></button>
      </div>
    </div>
  </div>
  <ngx-spinner></ngx-spinner>
</div>
