import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { Location } from "@angular/common";
@Component({
  selector: "app-material-detail",
  templateUrl: "./material-detail.component.html",
  styleUrls: ["./material-detail.component.scss"],
})
export class MaterialDetailComponent implements OnInit {
  marketingMaterialId: number | null = null;
  materials: any[] = [];
  materialCategory: any[] = [];

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.marketingMaterialId = Number(
      this.route.snapshot.paramMap.get("marketingMaterialId")
    );
    console.log("idParns", this.marketingMaterialId);

    const navigationState = history.state;

    if (navigationState) {
      if (navigationState.materials) {
        this.materials = navigationState.materials;
      }

      if (navigationState.selectedAllMaterial) {
        this.materialCategory = navigationState.selectedAllMaterial;
      }
    }

    if (!this.materials || !this.materialCategory) {
      console.error("Nenhum material encontrado na navegação");
    }
  }

  downloadMaterial(url: string, name: string): void {
    this.http.get(url, { responseType: "blob" }).subscribe(
      (blob) => {
        const blobUrl = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = blobUrl;
        a.download = name || "material.pdf";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(blobUrl);
      },
      (error) => {
        console.error("Erro ao baixar o arquivo:", error);
      }
    );
  }
  goBack(): void {
    this.location.back();
  }
}
