<div class="container mt-4">
  <div class="row">
    <i
      class="primary ft-chevron-left"
      (click)="goBack()"
      style="font-weight: bolder; font-size: xx-large; cursor: pointer"
    ></i>
    <div *ngIf="materialCategory" class="ml-4">
      <h4>{{ materialCategory.name }}</h4>
      <p>{{ materialCategory.description || "Sem descrição disponível" }}</p>
    </div>
  </div>

  <div class="row">
    <div
      *ngFor="let material of materials"
      class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4"
    >
      <div class="card shadow-sm h-100">
        <a>
          <img
            [src]="material.cover"
            [alt]="material.name"
            class="card-img-top"
          />
        </a>
        <div class="card-body d-flex flex-column">
          <h5 class="card-title">{{ material.name }}</h5>
          <p class="card-text flex-grow-1">
            {{ material.description || "Sem descrição disponível" }}
          </p>
          <button
            class="btn btn-primary"
            (click)="downloadMaterial(material.url, material.name)"
          >
            <i class="fa fa-download"></i> Download
          </button>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="materials.length === 0">
    <p>Nenhum material encontrado para o grupo selecionado.</p>
  </div>
</div>
