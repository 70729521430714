import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import Api from "app/helpers/api";
import {
  MarketplaceProductsRequestType,
  MarketplaceProductsReturnType,
  ProductType,
} from "app/personal/pages/finance-store/services-and-products/services-and-products.types";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { CopyCheckoutUrlModalComponent } from "../copy-checkout-url-modal/copy-checkout-url-modal.component";
import { ClipboardService } from "ngx-clipboard";
import App from "app/helpers/app";
import { asyncScheduler, scheduled } from "rxjs";
import { map } from "rxjs/operators";
import { ProductCreationAndEditionModalComponent } from "../product-creation-and-edition-modal/product-creation-and-edition-modal.component";

@Component({
  selector: "app-active-and-inactive-products",
  templateUrl: "./active-and-inactive-products.component.html",
  styleUrls: ["./active-and-inactive-products.component.scss"],
})
export class ActiveAndInactiveProductsComponent implements OnInit {
  apiCall = this.api.new().silent();

  products: ProductType[] | null = null;
  productsP = { page: 1, size: 10, total: 0 };

  isLoading = false;
  seller: any = [];
  @Input("onlyActive") onlyActive: boolean | null = null;

  constructor(
    private cdr: ChangeDetectorRef,
    private modalService: NgbModal,
    private clipboardApi: ClipboardService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private app: App,
    private api: Api
  ) {}

  ngOnInit(): void {
    this.getProducts({
      limit: this.productsP.size,
      offset: (this.productsP.page - 1) * this.productsP.size,
      status:
        this.onlyActive === true
          ? true
          : this.onlyActive === false
          ? false
          : true,
    }).subscribe(() => this.cdr.detectChanges());
  }

  getProducts(query: MarketplaceProductsRequestType) {
    this.isLoading = true;

    return this.apiCall
      .get<MarketplaceProductsReturnType | null>(
        `marketplace/products?${new URLSearchParams(
          Object.entries(query) as unknown as string[][]
        ).toString()}`
      )
      .pipe(
        map((response) => {
          this.isLoading = false;

          if (response.success && response.return) {
            this.products = response.return.rows;
            console.log("produtos", this.products);
            this.productsP.total = response.return.count;
          } else {
            this.toastr.error(response.message, "Ops :(");
          }

          return scheduled([response], asyncScheduler);
        })
      );
  }

  onPageChange() {
    this.ngOnInit();
  }

  switchProductStatus(productId: number, status: boolean) {
    this.app
      .confirm(
        "Tem certeza?",
        status
          ? "Ao ativar o produto, o mesmo ficará disponível para vendas"
          : "Ao inativar o produto, não será possível realizar novas vendas do mesmo"
      )
      .then((result) => {
        if (result) {
          this.spinner.show(undefined, {
            type: "ball-triangle-path",
            size: "medium",
            bdColor: "rgba(0, 0, 0, 0.8)",
            color: "#fff",
            fullScreen: true,
          });

          this.apiCall
            .put("seller/me/subconta/switchproductstatus/", {
              product: productId,
              status,
            })
            .subscribe((response) => {
              if (response.success) {
                this.productsP.page = 1;
                this.productsP.total = 0;

                this.getProducts({
                  limit: this.productsP.size,
                  offset: (this.productsP.page - 1) * this.productsP.size,
                  status:
                    this.onlyActive === true
                      ? true
                      : this.onlyActive === false
                      ? false
                      : true,
                }).subscribe(() => {
                  this.spinner.hide();
                  this.cdr.detectChanges();
                  this.toastr.success(
                    status
                      ? "Produto Ativado com Sucesso! "
                      : "Produto Desativado com Sucesso!",
                    "Tudo certo!"
                  );
                });
              } else {
                this.spinner.hide();
                this.toastr.error(response.message, "Ops :(");
              }
            });
        }
      });
  }

  editProduct(product: ProductType) {
    const instance = this.modalService.open(
      ProductCreationAndEditionModalComponent,
      {
        centered: true,
        size: "lg",
      }
    ).componentInstance as ProductCreationAndEditionModalComponent;

    instance.defaultProduct = product;
    instance.callback = (status: "success" | "error") => {
      if (status === "success") {
        this.productsP.page = 1;
        this.productsP.total = 0;

        this.getProducts({
          limit: this.productsP.size,
          offset: (this.productsP.page - 1) * this.productsP.size,
          status:
            this.onlyActive === true
              ? true
              : this.onlyActive === false
              ? false
              : true,
        }).subscribe(() => this.cdr.detectChanges());
      }
    };
  }

  openCopyCheckoutUrl(product: ProductType) {
    if (product.checkout_url) {
      const copied = this.clipboardApi.copyFromContent(product.checkout_url);

      if (copied) {
        this.toastr.success("Link copiado com sucesso!");
      }

      const componentInstance = this.openModal(CopyCheckoutUrlModalComponent)
        .componentInstance as CopyCheckoutUrlModalComponent;

      componentInstance.checkoutUrl = product.checkout_url;
    } else {
      this.toastr.error("Não foi possivel gerar o link de venda.", "Ops :(");
    }
  }

  openThankYouPage(product: ProductType) {
    const url =
      "https://app.opersonaldigital.com.br/pages/posvenda/" +
      btoa(JSON.stringify(product.id)).replace("==", "").replace("=", "") +
      "/" +
      "view" +
      "/?sellerview=1";

    window.open(url, "_blank");
  }

  openModal(content: any, size?: string) {
    return this.modalService.open(content, {
      centered: true,
      size: size || "xl",
    });
  }

  toggleProductInStore(product: any) {
    if (product.sellerStoreId !== null) {
      this.removeFromStore(product.id);
    } else {
      this.addInStore(product);
    }
  }

  removeFromStore(productId: number) {
    this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    this.apiCall
      .put(`marketplace/product/removefromstore/${productId}`)
      .subscribe((response) => {
        this.spinner.hide();

        if (response.success) {
          this.toastr.success(
            "Produto removido da vitrine com Sucesso!",
            "Tudo certo!"
          );
          this.ngOnInit();
        } else {
          this.toastr.error(response.message, "Ops :(");
        }
      });
  }

  addInStore(product: any) {
    this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    const body = {
      products: [{ id: product.id }],
    };

    this.apiCall
      .put("marketplace/products/addtostore", body)
      .subscribe((response) => {
        this.spinner.hide();

        if (response.success) {
          this.toastr.success(
            "Produto adicionado à vitrine com Sucesso!",
            "Tudo certo!"
          );
          this.ngOnInit();
        } else {
          this.toastr.error(response.message, "Ops :(");
        }
      });
  }
  userInfo(): void {
    this.apiCall.get("user/seller-store").subscribe(
      (data) => {
        this.seller = data.return;
      },
      (error) => {
        console.log("Erro ao obter informações da loja:", error);
      }
    );
  }
}
