<div class="row" style="margin-left: 80px">
  <button
    class="back-button mt-0"
    (click)="goBack()"
    [style.backgroundColor]="selectedColor"
    [style.color]="calculateTextColor(selectedColor)"
  >
    <i class="arrow-left"></i>
  </button>
  <p style="font-size: 24px; font-weight: 700">Assinaturas</p>
</div>

<ul class="subscription-list">
  <li
    *ngFor="let subscription of paginatedSubscriptions"
    class="subscription-item"
  >
    <div class="subscription-content" (click)="toggleDetails(subscription)">
      <div class="subscription-image col-auto">
        <img [src]="subscription.cover" alt="{{ subscription.name }}" />
      </div>
      <div
        class="destaque"
        [style.backgroundColor]="selectedColor"
        [style.color]="calculateTextColor(selectedColor)"
        *ngIf="subscription.isFeatured == true"
      >
        <p>Destaque</p>
      </div>
      <div class="subscription-details">
        <p class="title">{{ subscription.name }}</p>

        <p *ngIf="isExpanded(subscription) || !isMobile">
          {{ subscription.description }}
        </p>
        <div class="row">
          <span class="subscription-price" [style.color]="selectedColor">
            {{ integerToReal(subscription.Plans[0]?.value_cents) }}
          </span>
          <span style="margin-top: 14px; margin-left: 4px">/</span>
          <span class="subscription-cycles">
            {{
              getIntervalTypeInPortuguese(subscription.Plans[0]?.interval_type)
            }}
          </span>
        </div>

        <div
          class="subscription-button"
          *ngIf="!isMobile || isExpanded(subscription)"
        >
          <button
            (click)="buySubscription(subscription.checkout_url)"
            [style.backgroundColor]="selectedColor"
            [style.color]="calculateTextColor(selectedColor)"
          >
            Comprar
          </button>
        </div>
      </div>

      <div *ngIf="isMobile">
        <i
          class="fa"
          [ngClass]="{
            'fa-chevron-right': !isExpanded(subscription),
            'fa-chevron-down': isExpanded(subscription)
          }"
          style="color: darkgrey"
          (click)="toggleDetails(subscription)"
        ></i>
      </div>
    </div>
  </li>
</ul>

<ngb-pagination
  *ngIf="totalItems > 0"
  [(page)]="page"
  [pageSize]="pageSize"
  [maxSize]="2"
  [rotate]="true"
  [boundaryLinks]="true"
  size="xl"
  [collectionSize]="totalItems"
  (pageChange)="onPageChange($event)"
  class="pagination"
></ngb-pagination>

<ngx-spinner></ngx-spinner>
