import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import Api from "app/helpers/api";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-personal-marketplace",
  templateUrl: "./personal-marketplace.component.html",
  styleUrls: ["./personal-marketplace.component.scss"],
})
export class PersonalMarketplaceComponent implements OnInit {
  apiCall = this.api.new();
  products: any[] = [];
  subscriptions: any[] = [];
  seller: any = [];
  slug: string;
  selectedColor: string;
  constructor(
    private api: Api,
    private spinner: NgxSpinnerService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.route.paramMap.subscribe((params) => {
      this.slug = params.get("slug");
    });
  }

  ngOnInit(): void {
    this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    this.sellerInfo();
  }

  sellerInfo() {
    this.apiCall.get(`/seller-store/slug/${this.slug}`).subscribe(
      (data) => {
        this.seller = data.return;
        this.selectedColor = this.seller.baseColor
          ? this.seller.baseColor
          : "#F77E17";
        this.loadMarketplaceData(0, "products");
        this.loadMarketplaceData(1, "subscriptions");

        this.spinner.hide();
      },
      (error) => {
        console.log("Erro ao obter informações da loja:", error);
      }
    );
  }

  loadMarketplaceData(type: number, dataType: "products" | "subscriptions") {
    if (!this.seller || !this.seller.id) {
      return;
    }

    this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    const offset = 0;
    const limit = 10;
    const sellerStoreId = this.seller.id;

    const url = `marketplace/customer/view?type=${type}&offset=${offset}&limit=${limit}&sellerStoreId=${sellerStoreId}`;

    this.apiCall.get(url).subscribe((response) => {
      if (response.code === 200 && !response.error) {
        if (dataType === "products") {
          this.products = response.return.rows;
        } else if (dataType === "subscriptions") {
          this.subscriptions = response.return.rows;
        }
        this.spinner.hide();
      } else {
        console.error("Erro ao buscar dados:", response.message);
      }
    });
  }

  _modelRadio: "products" | "subscriptions" | "contact" = "products";
  get modelRadio() {
    return this._modelRadio;
  }

  set modelRadio(value) {
    document.getElementById(`${value}-section`).scrollIntoView({
      behavior: "smooth",
    });
    this._modelRadio = value;
  }

  getContactTypeLabel(contactTypeId: number): string {
    switch (contactTypeId) {
      case 1:
        return "Instagram";
      case 2:
        return "WhatsApp";
      case 3:
        return "Email";
      case 4:
        return "Site";
      case 5:
        return "Outros";
      default:
        return "Contato";
    }
  }

  getIconClass(contactTypeId: number): string {
    switch (contactTypeId) {
      case 1:
        return "fa fa-instagram";
      case 2:
        return "fa fa-whatsapp";
      case 3:
        return "fa fa-envelope-o";
      case 4:
        return "fa fa-globe";
      case 5:
        return "fa fa-heart";
      default:
        return "";
    }
  }

  openContact(contact: any): void {
    console.log("contato", contact);
    switch (contact.contactTypeId) {
      case 2:
        this.openWhatsApp(contact.contact);
        break;
      case 3:
        this.openEmail(contact.contact);
        break;
      case 1:
        this.openInstagram(contact.contact);
        break;
      case 5:
        this.openSocialMedia(contact.contact);
        break;
      default:
        console.log("Tipo de contato desconhecido");
    }
  }

  openInstagram(username: string): void {
    if (username && username.startsWith("http")) {
      window.open(username, "_blank");
    } else {
      const url = `https://instagram.com/${username}`;
      window.open(url, "_blank");
    }
  }

  openWhatsApp(phone: string): void {
    const formattedPhone = phone.replace(/\D/g, "");
    if (phone && phone.startsWith("http")) {
      window.open(phone, "_blank");
    } else {
      const url = `https://wa.me/${formattedPhone}`;
      window.open(url, "_blank");
    }
  }

  openEmail(email: string): void {
    if (email && email.startsWith("http")) {
      window.open(email, "_blank");
    } else {
      const url = `https://mail.google.com/mail/?view=cm&fs=1&to=${email}`;
      window.open(url, "_blank");
    }
  }

  openSocialMedia(url: string): void {
    if (url && url.startsWith("http")) {
      window.open(url, "_blank");
    } else {
      console.error("URL inválido:", url);
    }
  }

  goToProducts() {
    this.router.navigate(["/products"]);
  }

  goToSubscriptions() {
    this.router.navigate(["/subscriptions"]);
  }

  scrollToSection(section: string) {
    const element = document.getElementById(section);
    if (element) {
      const yOffset = -70;
      const yPosition =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: yPosition, behavior: "smooth" });
    }
  }

  calculateTextColor(bgColor: string): string {
    const color = bgColor.replace("#", "");

    const fullColor =
      color.length === 3
        ? color
            .split("")
            .map((c) => c + c)
            .join("")
        : color;

    const r = parseInt(fullColor.substring(0, 2), 16);
    const g = parseInt(fullColor.substring(2, 4), 16);
    const b = parseInt(fullColor.substring(4, 6), 16);

    const referenceR = parseInt("ff", 16);
    const referenceG = parseInt("66", 16);
    const referenceB = parseInt("00", 16);

    if (
      r < referenceR ||
      (r === referenceR && g < referenceG) ||
      (r === referenceR && g === referenceG && b < referenceB)
    ) {
      return "white";
    }

    return "grey";
  }
}
