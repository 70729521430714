import { Component, Input, ViewChild } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-tutoriais",
  templateUrl: "./tutoriais.component.html",
  styleUrls: ["./tutoriais.component.scss"],
})
export class TutoriaisComponent {
  @Input() tutorials: any[] = [
    {
      index: 1,
      title: "Como criar sua loja",
      description: "Passo a passo para criar sua loja.",
      url: "https://files.wecodde.com/file/wecodde/Como_criar_sua_loja.mp4",
    },
    {
      index: 2,
      title: "O que é a Nutriplan",
      description: "",
      url: "https://files.wecodde.com/file/wecodde/O_que_e_a_Nutriplan.mp4",
    },
    {
      index: 3,
      title: "Nutriplan na Hotmart",
      description: "Como copiar o link da Nutriplan na Hotmart",
      url: "https://files.wecodde.com/file/wecodde/Nutriplan_na_Hotmart.mp4",
    },
    {
      index: 4,
      title: "Como ver sua Loja",
      description: "",
      url: "https://files.wecodde.com/file/wecodde/ver_loja.mp4",
    },
    {
      index: 5,
      title: "Como Acompanhar suas vendas",
      description: "",
      url: "https://files.wecodde.com/file/wecodde/Como_acompanhar_suas_vendas.mp4",
    },
  ];

  urlToPlay: string;
  safeUrlToPlay: SafeResourceUrl;

  private videoModalRef;

  @ViewChild("videoModal") videoModal;

  constructor(private _sanitizer: DomSanitizer, private modal: NgbModal) {}

  viewMidia(urlToPlay: string) {
    this.urlToPlay = urlToPlay;
    this.safeUrlToPlay =
      this._sanitizer.bypassSecurityTrustResourceUrl(urlToPlay);
    this.videoModalRef = this.modal.open(this.videoModal, {
      centered: true,
      size: "lg",
    });
  }

  closeModal() {
    if (this.videoModalRef) {
      this.videoModalRef.close();
      this.videoModalRef = null;
    } else {
      this.modal.dismissAll();
    }
  }
}
