<div class="row mt-3">
  <div class="col-12">
    <p class="sub-texto font-size-4 font-size-md-7 m-0">
      Se tem dúvidas sobre como cadastrar seus produtos,
      <button
        type="button"
        class="btn-tutorial btn btn-outline-warning font-size-4 font-size-md-7 text-bold-600 border-0 rounded-0 p-0 d-inline-block"
        (click)="openTutoriaisModal()"
      >
        veja o vídeo tutorial.
      </button>
    </p>
  </div>
  <div class="col-12 mt-2">
    <div class="w-100 d-flex justify-content-end">
      <button
        *ngIf="activeTabId === 'on-store'"
        [disabled]="seller?.active != true"
        type="button"
        (click)="openStore()"
        class="btn btn-outline-secondary font-size-5 font-size-md-6 d-flex align-items-center mr-2"
      >
        <i class="ft-eye mr-1 mr-md-2"></i>
        Ver Loja
      </button>
      <button
        *ngIf="activeTabId === 'on-store'"
        type="button"
        class="btn btn-warning font-size-4 font-size-md-6 d-flex align-items-center"
        (click)="openModal(actionModal)"
      >
        Escolher Ação
      </button>

      <button
        *ngIf="activeTabId !== 'on-store'"
        type="button"
        (click)="openAddProduct()"
        class="btn btn-warning font-size-4 font-size-md-6 d-flex align-items-center"
      >
        Criar Produto/Serviço
      </button>
    </div>
  </div>
  <div class="col-12 mt-2">
    <div class="card">
      <div class="card-content">
        <div class="card-body p-2 p-md-3">
          <div class="row">
            <div class="col-12">
              <ul
                ngbNav
                [(activeId)]="activeTabId"
                #nav="ngbNav"
                class="nav-tabs flex-grow-1 d-flex justify-content-start border-0"
              >
                <li ngbNavItem [ngbNavItem]="'active'" class="nav-tab-item">
                  <a
                    ngbNavLink
                    [routerLink]="null"
                    [queryParams]="{ tab: 'active' }"
                    class="rounded font-size-4 font-size-md-6"
                  >
                    Ativos
                  </a>
                  <ng-template ngbNavContent>
                    <app-active-and-inactive-products
                      *ngIf="showProducts"
                      [onlyActive]="true"
                    >
                    </app-active-and-inactive-products>
                  </ng-template>
                </li>
                <li ngbNavItem [ngbNavItem]="'inactive'" class="nav-tab-item">
                  <a
                    ngbNavLink
                    [routerLink]="null"
                    [queryParams]="{ tab: 'inactive' }"
                    class="rounded font-size-4 font-size-md-6"
                  >
                    Inativos
                  </a>
                  <ng-template ngbNavContent>
                    <app-active-and-inactive-products
                      *ngIf="showProducts"
                      [onlyActive]="false"
                    >
                    </app-active-and-inactive-products>
                  </ng-template>
                </li>
                <li
                  ngbNavItem
                  [ngbNavItem]="'on-store'"
                  class="nav-tab-item"
                  [disabled]="seller?.id != null || seller?.active != true"
                  [ngbTooltip]="
                    seller?.id == null || seller?.active != true
                      ? 'A aba só estará disponível se você tiver uma loja ativa'
                      : ''
                  "
                  tooltipClass="custom-tooltip"
                  placement="top"
                >
                  <a
                    ngbNavLink
                    [routerLink]="null"
                    [queryParams]="{ tab: 'on-store' }"
                    class="rounded font-size-4 font-size-md-6"
                    [class.disabled-link]="
                      seller?.id != null || seller?.active != true
                    "
                    (click)="
                      seller?.id != null || seller?.active != true
                        ? $event.preventDefault()
                        : null
                    "
                  >
                    Na Loja
                  </a>
                  <ng-template ngbNavContent>
                    <app-on-store-products
                      *ngIf="showProducts"
                    ></app-on-store-products>
                  </ng-template>
                </li>
              </ul>
            </div>
            <div class="col-12">
              <div [ngbNavOutlet]="nav" class="p-0"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #actionModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Selecione uma Ação</h5>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <button
      class="btn btn-warning btn-block mb-2"
      (click)="selectAction('addProducts', modal)"
    >
      Adicionar Produtos
    </button>
    <button
      class="btn btn-warning btn-block"
      (click)="selectAction('createProduct', modal)"
    >
      Criar Produto/Serviço
    </button>
  </div>
</ng-template>

<ng-template #tutorialVideo>
  <div class="embed-container">
    <iframe [src]="tutorialVideoUrl" frameborder="0" allowfullscreen></iframe>
  </div>
</ng-template>
