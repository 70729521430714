import { Component, OnInit } from '@angular/core';
import Api from "../helpers/api";
import App from "../helpers/app";
import { NgxSpinnerService } from "ngx-spinner";
import * as moment from 'moment';
import { take } from 'rxjs/operators';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-admin-indique-e-ganhe',
  templateUrl: './admin-indique-e-ganhe.component.html',
  styleUrls: ['./admin-indique-e-ganhe.component.scss']
})
export class AdminIndiqueEGanheComponent implements OnInit {

  apiCall = this.api.new();
  faturas = []
  comissoes = []
  faturasMeseAno = []
  personais = []
  indicadores = []
  faturasAgrupadas = []
  page = 1
  pageSize = 10
  faturasPersonal = []
  saldoComissao = null
  personalSelected = null
  bankAccountSelected = null




  constructor(private modalService: NgbModal,
    private api: Api,
    private app: App,
    private spinner: NgxSpinnerService,
  ) {

  }

  async ngOnInit() {

    const self = this

    self.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });


    const faturas = await self.apiCall.get('admin/users/faturasindiqueeganhe').pipe(take(1)).toPromise()
    const personais = await self.apiCall.get('admin/users/personaisindiqueeganhe').pipe(take(1)).toPromise()

   // self.faturas = faturas.return.faturas
    self.comissoes = faturas.return.comissoes
    self.personais = personais.return

    let penultimoMes = moment().subtract(2, 'months').endOf('month')

    for (let p of self.personais) {

      p.comissoes = []
      p.saldoLiberado = 0
      p.saldoPendente = 0

      let faturasPersonal = this.comissoes.filter(f => f.userIndicatedId == p.id)

      for (let i of faturasPersonal) {
        if (moment(i.invoicePaymentDate).isSameOrBefore(penultimoMes) && i.paymentId == null) {
          p.saldoLiberado = p.saldoLiberado + i.commissionValue
          i.isLiberado = true
        } else if(moment(i.invoicePaymentDate).isAfter(penultimoMes)) {
          p.saldoPendente = p.saldoPendente + i.commissionValue
        }
        p.comissoes.push(i)
      }

    }


    self.personais = self.personais.sort((a, b) => {
      return b.saldoLiberado - a.saldoLiberado;
  });

    await self.spinner.hide()

  }

  formatDate(date) {
    return moment(date).format('DD/MM/YYYY')
  }

  formatPreco(valor) {
    let retorno = (valor / 100).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
    return retorno
  }

  formatPlan(plano) {
    if(plano == "plano_1"){
        return "Mensal"
    }else if(plano == "plano_2" || plano == "plano_2_parcelado" || plano == "plano_2_boleto"){
        return "Semestral"
    }else if(plano == "plano_3" || plano == "plano_3_parcelado" || plano == "plano_3_boleto"){
        return "Anual"
    }
  }

  async openPersonalIndicacao(personal, modal) {

    this.personalSelected = personal
    this.faturasPersonal = personal.comissoes.filter(f => f.isLiberado == true && f.paymentId == null)
    this.saldoComissao = this.formatPreco(this.personalSelected.saldoLiberado)
    const modalRef = this.modalService.open(modal, { centered: true, size: "lg", keyboard: false });

  }


  gerarPagamento(modal) {

    this.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });

    let chamada

    let body = {
      pagamento: {
        user: this.personalSelected.id,
        value: this.personalSelected.saldoLiberado,
        comprovante: null,
      },
      faturas: this.faturasPersonal
    }


    chamada = this.apiCall.post('cashback/payment', body)

    chamada.subscribe(async data => {
      if (data.success) {
        this.spinner.hide()
        await this.app.alert('Tudo certo!', 'Pagamento Salvo com Sucesso', 'success')
        modal.close('Close click')
        this.ngOnInit()
      } else {
        await this.app.alert('Ops :(', data.message, 'error')
        this.spinner.hide()
      }
    });

  }

  viewBankAccount(bankAccount, modal){
    const self = this 

    self.bankAccountSelected = bankAccount
    console.log(self.bankAccountSelected[0])

    const modalRef = this.modalService.open(modal, { centered: true, keyboard: false });

  }

  formatAccountType(accountType){

    switch(accountType){
      case 1:
      return 'Conta Corrente'
      case 2:
      return 'Conta Poupança'
      case 3:
      return 'Conta de Pagamento'
    }
  
  }


  openWhatsapp(phoneCustomer) {
    let phoneFormated = phoneCustomer.replace('+', '');
    if (phoneFormated.length == 11) {
      phoneFormated = phoneFormated.padStart(13, '55');
    }
    window.open(`https://api.whatsapp.com/send?phone=${phoneFormated}`, "_blank");
  }


  notificaCadatroConta(user) {

    this.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });

    let chamada

    let body = {
      user: {
        name: user.name,
        email: user.email,
        phone: user.phone,
      }
    }


    chamada = this.apiCall.post('seller/refer/pendingAccountResister', body)

    chamada.subscribe(async data => {
      if (data.success) {
        this.spinner.hide()
        await this.app.alert('Tudo certo!', 'Notificação Enviada com Sucesso', 'success')
      } else {
        await this.app.alert('Ops :(', data.message, 'error')
        this.spinner.hide()
      }
    });

  }

}

