<div class="row my-3">
  <div class="col">
    <div class="card-content">
      <div class="card-body p-0">
        <div class="table-responsive-md">
          <table class="table table-striped text-center m-0">
            <thead>
              <tr>
                <th class="p-2 font-size-4 font-size-md-6">Nome</th>
                <th class="p-2 font-size-4 font-size-md-6">Tipo de Cobrança</th>
                <th class="p-2 font-size-4 font-size-md-6">Preço</th>
                <th
                  class="p-2 font-size-4 font-size-md-6"
                  *ngIf="onlyActive == true && seller.id != null"
                >
                  Na Loja
                </th>
                <th class="p-2 font-size-4 font-size-md-6">Ações</th>
              </tr>
            </thead>
            <tbody>
              <ng-container
                *ngIf="!isLoading && products && products.length > 0"
              >
                <tr *ngFor="let product of products">
                  <td class="font-size-4 font-size-md-6">{{ product.name }}</td>
                  <td class="font-size-4 font-size-md-6">
                    {{ product.plan_type == 0 ? "Compra única" : "Recorrente" }}
                  </td>
                  <td class="font-size-4 font-size-md-6">
                    {{
                      product.plan_type == 0 && product.value_cents
                        ? (product.value_cents / 100 | currency : "R$")
                        : product.Plans && product.Plans[0]
                        ? (product.Plans[0].value_cents / 100 | currency : "R$")
                        : "---"
                    }}
                  </td>

                  <td
                    class="font-size-4 font-size-md-6"
                    *ngIf="onlyActive == true"
                  >
                    <!-- Toggle SVG Button -->
                    <ui-switch
                      [checked]="product.sellerStoreId !== null"
                      class="switchery"
                      placement="top"
                      ngbTooltip="Ative/Inative a relação com seu aluno."
                      (change)="toggleProductInStore(product)"
                      [(ngModel)]="product.sellerStoreId"
                    ></ui-switch>
                  </td>

                  <td class="font-size-4 font-size-md-6">
                    <div class="dropdown btn-group-sm" ngbDropdown>
                      <button
                        type="button"
                        class="btn btn-outline-warning btn-sm dropdown-toggle"
                        ngbDropdownToggle
                      >
                        <i class="ft-more-vertical"></i>
                      </button>
                      <div ngbDropdownMenu class="dropdown-menu" role="menu">
                        <button
                          type="button"
                          class="w-100 dropdown-item"
                          (click)="editProduct(product)"
                        >
                          Editar Produto
                        </button>
                        <button
                          type="button"
                          class="w-100 dropdown-item"
                          (click)="
                            switchProductStatus(product.id, !product.status)
                          "
                        >
                          {{
                            product.status
                              ? "Desativar Produto"
                              : "Ativar Produto"
                          }}
                        </button>
                        <button
                          type="button"
                          class="w-100 dropdown-item"
                          (click)="openCopyCheckoutUrl(product)"
                        >
                          Gerar Link de Venda
                        </button>
                        <button
                          type="button"
                          class="w-100 dropdown-item"
                          (click)="openThankYouPage(product)"
                        >
                          Ver Página de Obrigado
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              </ng-container>
              <tr *ngIf="isLoading">
                <td colspan="5">
                  <div
                    class="w-100 d-flex justify-content-center align-items-center overflow-hidden my-3 my-md-4"
                  >
                    <div
                      class="d-flex justify-content-center align-items-center load-spinner-content"
                    >
                      <div class="load-spinner">
                        <i class="ft-loader text-primary"></i>
                      </div>
                      <span class="ml-2 font-size-4 font-size-md-5">
                        Carregando...
                      </span>
                    </div>
                  </div>
                </td>
              </tr>
              <tr
                *ngIf="
                  !isLoading &&
                  (!products || (products && products.length === 0))
                "
              >
                <td colspan="5">
                  <div class="row my-2 my-lg-3">
                    <div class="col">
                      <div class="card-content">
                        <div
                          class="card-body p-0 d-flex justify-content-center"
                        >
                          <span class="font-size-4 font-size-md-6">
                            Nenhum Plano
                            {{ onlyActive ? "Ativo" : "Inativo" }}!
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="d-flex justify-content-center mt-2 mx-auto">
        <ngb-pagination
          size="sm"
          [(page)]="productsP.page"
          [pageSize]="productsP.size"
          [maxSize]="2"
          [rotate]="true"
          [disabled]="isLoading"
          [boundaryLinks]="true"
          [collectionSize]="productsP.total"
          (pageChange)="onPageChange()"
        ></ngb-pagination>
      </div>
    </div>
  </div>
</div>
