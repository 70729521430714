<div class="container mt-4">
  <h1 class="h4 font-weight-bold mb-2">Materiais Extras</h1>
  <p class="text-muted mb-4">
    Materiais editáveis para personalizar e impulsionar suas redes sociais.
  </p>
  <!-- 
  <div class="d-flex align-items-center border alert-one-line p-2" role="alert">
    <svg
      class="mx-2"
      width="20"
      height="18"
      viewBox="0 0 24 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.0967 18.5591C22.315 17.3943 21.1271 15.9956 19.7518 14.6204C18.9964 13.8649 18.2033 13.1388 17.4585 12.5207C17.3723 12.4491 17.2568 12.4238 17.1486 12.4527C17.0404 12.4816 16.9528 12.5611 16.9138 12.6661C16.7973 12.9789 16.6075 13.2681 16.3497 13.5258C16.138 13.7376 15.9182 13.9043 15.6963 14.0212C15.604 14.0699 15.5385 14.1577 15.5182 14.2602C15.4981 14.3628 15.5253 14.4688 15.5924 14.5489C16.1922 15.2656 16.8929 16.0281 17.6186 16.754C20.1193 19.2546 22.5489 21.001 23.5273 21.0011C23.6775 21.0011 23.7951 20.9597 23.8765 20.8783C24.2607 20.494 23.6646 19.405 23.0967 18.5591Z"
        fill="#F77E17"
      />
      <path
        d="M12.9692 11.426C13.0308 11.8161 13.1007 12.2582 13.3272 12.7461C13.6353 13.4096 14.1561 13.8057 14.7203 13.8058H14.7204C15.1468 13.8058 15.576 13.5926 15.9964 13.1723C16.5922 12.5765 16.7716 11.7955 16.4764 11.083C16.0847 10.1373 14.967 9.52631 13.6289 9.52631C13.3344 9.52631 13.0267 9.55466 12.7143 9.61061C12.5921 9.63253 12.4909 9.71837 12.4495 9.83555C12.408 9.95267 12.4327 10.0831 12.5139 10.1771C12.8295 10.542 12.8942 10.9515 12.9692 11.426Z"
        fill="#F77E17"
      />
      <path
        d="M21.9246 0H0.345733C0.154782 0 0 0.154782 0 0.345733V14.9977C0 15.1887 0.154782 15.3435 0.345733 15.3435H15.1436L14.4368 14.4355C13.0061 14.4355 12.5798 13.0563 12.4549 13.0563H2.28716V2.28716H19.9832V13.0563H19.1715L21.3742 15.3435H21.9246C22.1156 15.3435 22.2704 15.1887 22.2704 14.9977V0.345733C22.2704 0.154782 22.1156 0 21.9246 0Z"
        fill="#F77E17"
      />
      <path
        d="M11.9499 9.10765C12.0148 9.16818 12.0994 9.20057 12.1858 9.20057C12.2178 9.20057 12.2501 9.19616 12.2816 9.18701C12.4996 9.12408 12.8207 9.03286 13.1134 8.95526C13.1845 8.93643 13.2476 8.89542 13.2939 8.8383C13.3747 8.73841 13.4692 8.63341 13.5385 8.55671C13.8831 8.17412 14.3552 7.6502 14.1474 7.02708C14.0403 6.71501 13.8271 6.48449 13.5307 6.36045C13.3739 6.29482 13.2038 6.26296 13.0103 6.26296C12.341 6.26296 11.4977 6.66815 10.6049 7.09713C10.2067 7.28845 9.57202 7.59334 9.20172 7.70083C9.28927 7.47956 9.46208 7.1709 9.58367 6.95352C9.79478 6.57656 10.0131 6.18673 10.1252 5.80643C10.3696 4.978 9.98898 4.53339 9.73819 4.34041C9.73782 4.34015 9.73367 4.33701 9.73329 4.33669C9.5083 4.16792 9.25772 4.08234 8.98858 4.08234C8.21861 4.08234 7.44263 4.76487 6.81908 5.31331C6.56367 5.53798 6.36506 5.70813 6.22586 5.80829C6.11474 5.88823 6.06124 6.02621 6.08948 6.16014L6.22916 6.82368C6.25149 6.92979 6.32229 7.01926 6.42032 7.06532C6.51856 7.11149 6.6326 7.10889 6.7285 7.05841C7.02748 6.90107 7.33789 6.62794 7.69762 6.31152C7.99223 6.05238 8.29696 5.78441 8.57636 5.60175C8.69418 5.52474 8.78242 5.47968 8.84593 5.45335C8.76636 5.69164 8.54977 6.07833 8.4236 6.30375C8.20925 6.68682 7.98755 7.08298 7.88325 7.4626C7.63757 8.35666 8.15696 8.77633 8.38972 8.91404C8.56158 9.01739 8.75482 9.0676 8.98039 9.0676C9.57415 9.0676 10.3185 8.70995 11.1805 8.29581C11.61 8.08949 12.0541 7.87615 12.4283 7.73844C12.4572 7.72775 12.4856 7.71764 12.5134 7.70807C12.2496 8.00135 11.9383 8.36134 11.8479 8.78234C11.8222 8.90117 11.861 9.02473 11.9499 9.10765Z"
        fill="#F77E17"
      />
    </svg>

    <div class="flex-grow-1 text-truncate" style="font-size: 11px">
      <strong>No OPD ACADEMY,</strong> você aprende a editar esses materiais de
      forma simples, a criar posts incríveis com o ChatGPT e muito mais!
    </div>
    <a
      [routerLink]="'/page/personal/opd-academy'"
      class="btn btn-success btn-sm text-nowra mr-n6"
      style="font-size: 11px"
    >
      Clique aqui e acesse agora o OPD ACADEMY →
    </a>
  </div> -->

  <ul class="nav nav-tabs">
    <li class="nav-item" *ngFor="let category of categories">
      <button
        type="button"
        class="nav-link"
        style="background-color: unset"
        [class.active]="selectedCategory === category.id"
        (click)="selectCategory(category.id)"
      >
        <i [class]="category.icon" style="margin-right: 6px"></i>
        {{ category.name }}
      </button>
    </li>
  </ul>

  <div class="row">
    <div class="col-6 col-md-3 mb-4" *ngFor="let material of filteredMaterials">
      <div class="card shadow-sm h-100">
        <a (click)="goToMaterialDetail(material.id)">
          <img
            [src]="material.cover"
            [alt]="material.name"
            class="card-img-top"
          />
        </a>
        <h5 class="ml-2 mt-2">{{ material.name }}</h5>
        <p class="ml-2">{{ material.description }}</p>
      </div>
    </div>
  </div>
</div>
