import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  SimpleChanges,
  OnChanges,
} from "@angular/core";
import Swiper, { SwiperOptions } from "swiper";

@Component({
  selector: "app-marketplace-all-slider",
  templateUrl: "./marketplace-all-slider.component.html",
  styleUrls: ["./marketplace-all-slider.component.scss"],
})
export class MarketplaceAllSliderComponent
  implements OnInit, AfterViewInit, OnDestroy, OnChanges
{
  @Input("products") products: any[] = [];
  @Input("subscription") subscription: any[] = [];
  @Input("selectedColor") selectedColor: string;
  productsMatrix: any[] = [];
  subscriptionsMatrix: any[] = [];
  combinedMatrix: any[] = [];
  expandedDescriptions: boolean[] = [];
  swiperInterval: any;
  swiperInit = false;
  isMobile: boolean = false;
  swiperConfig: SwiperOptions = {
    spaceBetween: 40,
    centeredSlides: true,
    autoplay: false,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },

    on: {
      init: () => {
        this.swiperInit = true;
        this.updateNavigation();
      },
      slideChange: () => {
        this.updateNavigation();
      },
    },
  };

  @ViewChild("swiperGallery", { static: false }) swiperGallery: {
    swiper: Swiper;
  };

  constructor() {}

  ngOnInit(): void {
    console.log("tralalal", this.products);
    this.checkWindowSize();
    this.transformProducts();
    this.transformSubscriptions();
    this.combineMatrices();
    this.expandedDescriptions = this.combinedMatrix.map(() => false);
    window.addEventListener("resize", this.onResize.bind(this));
  }
  onResize() {
    this.transformProducts();
    this.checkWindowSize();
    setTimeout(() => {
      if (this.swiperGallery?.swiper) {
        this.swiperGallery.swiper.update();
      }
    }, 0);
  }
  checkWindowSize(): void {
    this.isMobile = window.innerWidth <= 768;
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes["products"] || changes["subscription"]) {
      this.transformProducts();
      this.transformSubscriptions();
      this.combineMatrices();
      setTimeout(() => {
        this.swiperGallery?.swiper?.update();
        this.updateNavigation();
      }, 0);
    }
  }
  toggleDescription(index: number): void {
    this.expandedDescriptions[index] = !this.expandedDescriptions[index];
  }
  getShortDescription(description: string): string {
    return description.length > 100
      ? description.substring(0, 100) + "..."
      : description;
  }
  ngAfterViewInit(): void {}

  transformSubscriptions() {
    console.log("subscription", this.subscription);
    const transformedSubscriptions = this.subscription.map((sub, i) => ({
      imgDesktop: sub.cover || "https://via.placeholder.com/1170x700",
      url: sub.checkout_url || "#",
      title: sub.name,
      isFeatured: sub.isFeatured,
      value: sub.value_cents,
      Plans: sub.Plans.map((plan, i) => ({
        interval: plan.interval_type,
        cycles: plan.max_cycles,
      })),
      value_with_discount: sub.Plans[0]?.value_cents || null,
      discount_percent: sub.Plans[0]?.value_cents
        ? `-${(
            ((sub.value_cents - sub.Plans[0].value_cents) / sub.value_cents) *
            100
          ).toFixed(0)}%`
        : null,
      description: sub.description || "Sem descrição disponível",
    }));

    const slidesPerView = window.innerWidth <= 768 ? 1 : 3;
    this.subscriptionsMatrix = [];
    for (let i = 0; i < transformedSubscriptions.length; i += slidesPerView) {
      this.subscriptionsMatrix.push(
        transformedSubscriptions.slice(i, i + slidesPerView)
      );
    }
  }
  transformProducts() {
    console.log("products", this.products);
    const transformedProducts = this.products.map((product, i) => ({
      imgDesktop: product.cover || "https://via.placeholder.com/1170x700",
      url: product.checkout_url || "#",
      title: product.name,
      isFeatured: product.isFeatured,
      value: product.value_cents,
      value_with_discount: product.Plans[0]?.value_cents || null,
      discount_percent: product.Plans[0]?.value_cents
        ? `-${(
            ((product.value_cents - (product.Plans[0]?.value_cents || 0)) /
              (product.value_cents || 1)) *
            100
          ).toFixed(0)}%`
        : null,
      description: product.description || "Sem descrição disponível",
    }));

    const slidesPerView = window.innerWidth <= 768 ? 1 : 3;

    this.productsMatrix = [];
    for (let i = 0; i < transformedProducts.length; i += slidesPerView) {
      this.productsMatrix.push(transformedProducts.slice(i, i + slidesPerView));
    }
  }
  combineMatrices() {
    const maxLength = Math.max(
      this.productsMatrix.length,
      this.subscriptionsMatrix.length
    );
    this.combinedMatrix = [];
    for (let i = 0; i < maxLength; i++) {
      const combinedSlide = [
        ...(this.productsMatrix[i] || []),
        ...(this.subscriptionsMatrix[i] || []),
      ];
      this.combinedMatrix.push(combinedSlide);
      console.log("combinedMatrix", this.combinedMatrix);
    }
  }
  getIntervalTypeInPortuguese(intervalType: string): string {
    const translations: { [key: string]: string } = {
      day: "Dia",
      week: "Semana",
      months: "Mês",
      year: "Ano",
    };

    return translations[intervalType] || intervalType;
  }

  initSliderPooling(): void {
    this.swiperInterval = setInterval(() => {
      if (
        this.swiperGallery.swiper.activeIndex ===
        this.productsMatrix.length - 1
      ) {
        this.swiperGallery.swiper.slideTo(0);
        return;
      }
      this.swiperGallery.swiper.slideNext();
    }, 3800);
  }

  goPrev(): void {
    this.ngOnDestroy();

    if (this.swiperGallery.swiper.activeIndex === 0) {
      this.swiperGallery.swiper.slideTo(this.productsMatrix.length - 1);
      return;
    }
    this.swiperGallery.swiper.slidePrev();
  }

  goNext(): void {
    this.ngOnDestroy();

    if (
      this.swiperGallery.swiper.activeIndex ===
      this.productsMatrix.length - 1
    ) {
      this.swiperGallery.swiper.slideTo(0);
      return;
    }
    this.swiperGallery.swiper.slideNext();
  }

  goToIndex(index: number) {
    this.ngOnDestroy();

    this.swiperGallery.swiper.slideTo(index);
  }

  openCheckout(url: string) {
    window.open(url, "_blank");
  }

  integerToReal(val: number) {
    return Intl.NumberFormat("pt-Br", {
      style: "currency",
      currency: "BRL",
    }).format(val / 100);
  }

  ngOnDestroy(): void {
    window.removeEventListener("resize", this.onResize);

    if (!this.swiperInterval) return;
    clearInterval(this.swiperInterval);
  }

  updateNavigation() {
    if (!this.swiperGallery || !this.swiperGallery.swiper) return;

    const swiper = this.swiperGallery.swiper;
    const totalSlides = this.productsMatrix.reduce(
      (count, group) => count + group.length,
      0
    );
    const slidesPerView = (this.swiperConfig.slidesPerView as number) || 3;
    const totalPages = Math.ceil(totalSlides / slidesPerView);
    const navigationEnabled = totalPages > 1;

    const pagination = document.querySelector(".swiper-pagination");
    if (pagination) {
      const bullets = pagination.querySelectorAll(".swiper-pagination-bullet");
      bullets.forEach((bullet, index) => {
        bullet.classList.toggle(
          "swiper-pagination-bullet-active",
          index === 0 && !navigationEnabled
        );
      });
    }
  }

  calculateTextColor(bgColor: string): string {
    const color = bgColor.replace("#", "");

    const fullColor =
      color.length === 3
        ? color
            .split("")
            .map((c) => c + c)
            .join("")
        : color;

    const r = parseInt(fullColor.substring(0, 2), 16);
    const g = parseInt(fullColor.substring(2, 4), 16);
    const b = parseInt(fullColor.substring(4, 6), 16);

    const referenceR = parseInt("ff", 16);
    const referenceG = parseInt("66", 16);
    const referenceB = parseInt("00", 16);

    if (
      r < referenceR ||
      (r === referenceR && g < referenceG) ||
      (r === referenceR && g === referenceG && b < referenceB)
    ) {
      return "white";
    }

    return "grey";
  }
}
