import { Component, OnInit } from "@angular/core";
import {
  NgbCalendar,
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbModal,
} from "@ng-bootstrap/ng-bootstrap";
import { CustomAdapter } from "app/shared/services/dateAdapter.service";
import { CustomDateParserFormatter } from "app/shared/services/dateParserFormatter.service";
import * as moment from "moment";
import { NgxSpinnerService } from "ngx-spinner";
import { take } from "rxjs/operators";
import Swal from "sweetalert2";
import Api from "../helpers/api";
import App from "../helpers/app";
import { ExcelService } from "../helpers/excel.service";

@Component({
  selector: "app-admin-assinaturas",
  templateUrl: "./admin-assinaturas.component.html",
  styleUrls: ["./admin-assinaturas.component.scss"],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
})
export class AdminAssinaturasComponent implements OnInit {
  filterName;
  filterEmail;
  filterStatusContato;
  filterTipoAssinatura;
  filterStatusAssinatura;
  assinaturaSelecionada;
  statusAtual;
  detalheContato;
  userView;
  pageTitle = "Assinantes que cancelaram";
  datas = {
    inicio: "",
    fim: "",
  };
  tipoAssinatura = [
    {
      id: null,
      name: "Todos",
    },
    {
      id: "bank_slip",
      name: "Boleto",
    },
    {
      id: "credit_card",
      name: "Cartão de Crédito",
    },
  ];
  statusContato = [
    {
      id: null,
      name: "Todos",
    },
    {
      id: "0",
      name: "Não Contactado",
    },
    {
      id: "1",
      name: "Contactado",
    },
    {
      id: "2",
      name: "Não Convertido",
    },
    {
      id: "3",
      name: "Convertido",
    },
  ];
  statusAssinatura = [
    {
      id: null,
      name: "Todos",
    },
    {
      id: "0",
      name: "Inativa",
    },
    {
      id: "1",
      name: "Ativa",
    },
  ];
  lastModal = null;
  page = 1;
  pageSize = 10;
  listaChurn = [];
  listaNovosAssinantes = [];
  listaCancelamentosIndiretos = [];
  listaAssinaturasAtivas = [];
  listaChurnFiltered = [];
  listaNovosAssinantesFiltered = [];
  listaCancelamentosIndiretosFiltered = [];
  listaAssinaturasAtivasFiltered = [];
  funnels = [];
  funnelToAdd = null

  apiCall = this.api.new().silent();

  get today() {
    return this.dateAdapter.toModel(this.ngbCalendar.getToday());
  }

  constructor(
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private app: App,
    private excelService: ExcelService,
    private api: Api,
    private ngbCalendar: NgbCalendar,
    private dateAdapter: NgbDateAdapter<string>
  ) {}

  async ngOnInit() {
    const self = this;

    self.filterName = null;
    self.filterEmail = null;
    self.userView = null;
    self.listaChurn = [];
    self.listaNovosAssinantes = [];
    self.listaCancelamentosIndiretos = [];
    self.listaChurnFiltered = [];
    self.listaNovosAssinantesFiltered = [];
    self.listaCancelamentosIndiretosFiltered = [];
    self.listaAssinaturasAtivasFiltered = [];

    await self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    let listasUsuarios = await self.apiCall
      .get("admin/userssubscriptions")
      .pipe(take(1))
      .toPromise();

    if (listasUsuarios.return) {
      self.listaChurn = listasUsuarios.return.listaChurn;
      self.listaNovosAssinantes = listasUsuarios.return.listaNovosAssinantes;
      self.listaCancelamentosIndiretos =
        listasUsuarios.return.listaCancelamentosIndiretos;
      self.listaAssinaturasAtivas =
        listasUsuarios.return.listaAssinaturasAtivas;

      self.listaChurnFiltered = self.listaChurn;
      self.listaNovosAssinantesFiltered = self.listaNovosAssinantes;
      self.listaCancelamentosIndiretosFiltered =
        self.listaCancelamentosIndiretos;
      self.listaAssinaturasAtivasFiltered = self.listaAssinaturasAtivas;
    }

    const funnels = await self.apiCall.get('recoveryContactFunnels').pipe(take(1)).toPromise()
    if(funnels) this.funnels = funnels.return

    await self.spinner.hide();
  }

  filter() {
    this.listaChurnFiltered = this.listaChurn;
    this.listaNovosAssinantesFiltered = this.listaNovosAssinantes;
    this.listaCancelamentosIndiretosFiltered = this.listaCancelamentosIndiretos;
    this.listaAssinaturasAtivasFiltered = this.listaAssinaturasAtivas;

    if (this.filterName) {
      this.listaChurnFiltered = this.listaChurnFiltered.filter(
        (x) =>
          x.User.name &&
          x.User.name.toLowerCase().includes(this.filterName.toLowerCase())
      );
      this.listaNovosAssinantesFiltered =
        this.listaNovosAssinantesFiltered.filter(
          (x) =>
            x.User.name &&
            x.User.name.toLowerCase().includes(this.filterName.toLowerCase())
        );
      this.listaCancelamentosIndiretosFiltered =
        this.listaCancelamentosIndiretosFiltered.filter(
          (x) =>
            x.User.name &&
            x.User.name.toLowerCase().includes(this.filterName.toLowerCase())
        );
      this.listaAssinaturasAtivasFiltered =
        this.listaAssinaturasAtivasFiltered.filter(
          (x) =>
            x.User.name &&
            x.User.name.toLowerCase().includes(this.filterName.toLowerCase())
        );
    }
    if (this.filterEmail) {
      this.listaChurnFiltered = this.listaChurnFiltered.filter((x) =>
        x.User.email.toLowerCase().includes(this.filterEmail.toLowerCase())
      );
      this.listaNovosAssinantesFiltered =
        this.listaNovosAssinantesFiltered.filter((x) =>
          x.User.email.toLowerCase().includes(this.filterEmail.toLowerCase())
        );
      this.listaCancelamentosIndiretosFiltered =
        this.listaCancelamentosIndiretosFiltered.filter((x) =>
          x.User.email.toLowerCase().includes(this.filterEmail.toLowerCase())
        );
      this.listaAssinaturasAtivasFiltered =
        this.listaAssinaturasAtivasFiltered.filter((x) =>
          x.User.email.toLowerCase().includes(this.filterEmail.toLowerCase())
        );
    }
    if (this.filterStatusContato && this.filterStatusContato.id !== null) {
      console.log("Fioltra",this.filterStatusContato)
      this.listaChurnFiltered = this.listaChurnFiltered.filter((x) => this.filtraPorStatus(x));
      this.listaNovosAssinantesFiltered = this.listaNovosAssinantesFiltered.filter((x) => this.filtraPorStatus(x));
      this.listaCancelamentosIndiretosFiltered = this.listaCancelamentosIndiretosFiltered.filter((x) => this.filtraPorStatus(x));
      this.listaAssinaturasAtivasFiltered = this.listaAssinaturasAtivasFiltered.filter((x) => this.filtraPorStatus(x));
  }
    if (this.filterTipoAssinatura && this.filterTipoAssinatura.id) {
      this.listaChurnFiltered = this.listaChurnFiltered.filter(
        (x) => x.paymentMethod == this.filterTipoAssinatura.id
      );
      this.listaNovosAssinantesFiltered =
        this.listaNovosAssinantesFiltered.filter(
          (x) => x.paymentMethod == this.filterTipoAssinatura.id
        );
      this.listaCancelamentosIndiretosFiltered =
        this.listaCancelamentosIndiretosFiltered.filter(
          (x) => x.paymentMethod == this.filterTipoAssinatura.id
        );
      this.listaAssinaturasAtivasFiltered =
        this.listaAssinaturasAtivasFiltered.filter(
          (x) => x.paymentMethod == this.filterTipoAssinatura.id
        );
    }
    if (this.datas.inicio && this.datas.fim) {
      this.listaChurnFiltered = this.listaChurnFiltered.filter(
        (x) =>
          x.invoices[0].vencimento >= this.datas.inicio &&
          x.invoices[0].vencimento <= this.datas.fim
      );
      this.listaNovosAssinantesFiltered =
        this.listaNovosAssinantesFiltered.filter(
          (x) =>
            x.invoices[0].vencimento >= this.datas.inicio &&
            x.invoices[0].vencimento <= this.datas.fim
        );
      this.listaCancelamentosIndiretosFiltered =
        this.listaCancelamentosIndiretosFiltered.filter(
          (x) =>
            x.invoices[0].vencimento >= this.datas.inicio &&
            x.invoices[0].vencimento <= this.datas.fim
        );
      this.listaAssinaturasAtivasFiltered =
        this.listaAssinaturasAtivasFiltered.filter(
          (x) =>
            x.invoices[0].vencimento >= this.datas.inicio &&
            x.invoices[0].vencimento <= this.datas.fim
        );
    }
    if (this.filterStatusAssinatura && this.filterStatusAssinatura.id) {
      this.listaAssinaturasAtivasFiltered =
        this.listaAssinaturasAtivasFiltered.filter(
          (x) => x.statusAssinatura == this.filterStatusAssinatura.id
        );
    }
  }

  filtraPorStatus(registro) {
    const recoveryStatus = registro.RecoveryContact?.recoveryStatus || "0"; // Assume "Não Contactado" se RecoveryContact não existir
    if(recoveryStatus==2){
      console.log("Registro",recoveryStatus)
      console.log("filtra",this.filterStatusContato.id)
    }
    return recoveryStatus == this.filterStatusContato.id;
}

  exportarListaUsuarios(listagem) {
    let dadosexport;

    switch (listagem) {
      case 1:
        dadosexport = this.listaChurnFiltered;
        break;
      case 2:
        dadosexport = this.listaNovosAssinantesFiltered;
        break;
      case 3:
        dadosexport = this.listaCancelamentosIndiretosFiltered;
        break;
      case 4:
        dadosexport = this.listaAssinaturasAtivasFiltered;
    }

    this.excelService.exportAsExcelFile(
      JSON.parse(JSON.stringify(dadosexport)),
      "listausuarios"
    );
  }

  openModal(content, lastUser, refresh) {
    this.userView = lastUser;
    this.lastModal = this.modalService.open(content, { centered: true });
    this.lastModal.result.then(
      (result) => {
        if (refresh) this.ngOnInit();
      },
      (reason) => {
        if (refresh) this.ngOnInit();
      }
    );
  }

  formatDateWithoutTime(date) {
    return moment(date).add(3, "hours").format("DD/MM/YYYY");
  }
  formatContactStatus(status) {
    switch (status) {
      case 0:
        return "Não Contactado";
        break;
      case 1:
        return "Contactado";
        break;
      case 2:
        return "Não Convertido";
        break;
      case 3:
        return "Convertido";
    }
  }

  openWhatsapp(phoneCustomer) {
    let phoneFormated = phoneCustomer.replace("+", "");
    if (phoneFormated.length == 11) {
      phoneFormated = phoneFormated.padStart(13, "55");
    }
    window.open(
      `https://api.whatsapp.com/send?phone=${phoneFormated}`,
      "_blank"
    );
  }
  limparDatas() {
    this.datas.inicio = "";
    this.datas.fim = "";
    this.filter();
  }
  async openAtualizaContato(usuario, modal) {
    this.statusAtual = null;
    this.detalheContato = null;
    this.assinaturaSelecionada = usuario.id;
    this.lastModal = this.modalService.open(modal, {
      centered: true,
      keyboard: false,
    });
  }
  async openAddUserToFunnel(usuario, modal) {

    const self = this

    if(usuario.RecoveryContact && usuario.RecoveryContact.recoveryStatus == 1){
      await this.app.alert("Ops :(", "Esse usuário já está em recuperação", "error");
      return
    }

    console.info(usuario)
    this.statusAtual = null;
    this.detalheContato = null;
    this.assinaturaSelecionada = usuario;
    this.lastModal = this.modalService.open(modal, {
      centered: true,
      keyboard: false,
    });
  }
  atualizaContato() {
    this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    let chamada;

    let body = {
      contato: {
        assinatura: this.assinaturaSelecionada,
        recoveryContact: this.statusAtual.id,
        contactDetails: this.detalheContato ? this.detalheContato : null,
      },
    };

    chamada = this.apiCall.put("admin/updaterecoverycontact", body);

    chamada.subscribe(async (data) => {
      if (data.success) {
        this.spinner.hide();
        await this.app.alert(
          "Tudo certo!",
          "Contato Salvo com Sucesso",
          "success"
        );
        this.lastModal.close("Close click");
        this.ngOnInit();
      } else {
        await this.app.alert("Ops :(", data.message, "error");
        this.spinner.hide();
      }
    });
  }
  updateTitle(lista) {
    switch (lista) {
      case 1:
        this.pageTitle = "Assinantes que cancelaram";
        break;
      case 2:
        this.pageTitle = "Assinantes novos com pagamentos não finalizados";
        break;
      case 3:
        this.pageTitle = "Assinantes existentes com pagamentos não finalizados";
        break;
      case 4:
        this.pageTitle = "Assinanturas ativas";
        break;
    }
  }

  manageRecoveryFunnel(funnel, user) {
    const self = this;

    Swal.fire({
      title: "Tem certeza?",
      text:
        funnel != true
          ? "Deseja adicionar o cliente no funil de recuperação ?"
          : "Deseja remover o cliente do funil de recuperação ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      confirmButtonText: "Confirmar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        self.spinner.show(undefined, {
          type: "ball-triangle-path",
          size: "medium",
          bdColor: "rgba(0, 0, 0, 0.8)",
          color: "#fff",
          fullScreen: true,
        });

        self.apiCall
          .put("seller/managerecoveryfunnel", {
            funnelStatus: !funnel,
            user: user,
          })
          .subscribe(async (data) => {
            if (data.success) {
              this.spinner.hide();
              await this.app.alert("Tudo certo!", data.message, "success");
              this.ngOnInit();
            } else {
              await this.app.alert("Ops :(", data.message, "error");
            }
          });
      }
    });
  }
  async addUserToRecoveryFunnel(){
    const self = this

   self.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
      

      let body = {
        userId: this.assinaturaSelecionada.user,
        funnelId: this.funnelToAdd.id,
        subscriptionId: this.assinaturaSelecionada.subscriptionId, 
        invoiceId: "null",
        planIdentifier: this.assinaturaSelecionada.planIdentifier,
        lrCode: "null",
        paymentMethod: this.assinaturaSelecionada.paymentMethod,
        dueDate: this.assinaturaSelecionada.expirationDate,
        step: 0,
        recoveryStatus: 1,
      }

      let data = await this.apiCall.post('recoveryContact', body).pipe(take(1))
      .toPromise();
        if (data.success) {
          this.lastModal.close("Close click");
          this.spinner.hide();
          await this.app.alert("Tudo certo!", "Contato Adicionado ao Funil automático", "success");
        } else {
          this.lastModal.close("Close click");
          await this.app.alert("Ops :(", data.message, "error");
        }
      

     self.spinner.hide()

      console.log(body)

  }

  checkRecoveryStatus(sub) {

    if(sub.RecoveryContact){

      if (sub.RecoveryContact.recoveryStatus == 1) {
        return "<span class='badge badge-warning'>Em Recuperação</span><br><small>Último Contato: "+moment(sub.RecoveryContact.lastContact).format("DD/MM/YY")+"</small>";
      } else {
        return "<span class='badge badge-info'>Não Está em Recuperação</span>";
      }

    }else{
      return "<span class='badge badge-info'>Não Está em Recuperação</span>";
    }
  }

  openNotificacoesInteligentes(phoneCustomer) {
    let phoneFormated = phoneCustomer.replace("+", "");
    if (phoneFormated.length == 11) {
      phoneFormated = phoneFormated.padStart(13, "55");
    }
    window.open(
      `https://app.notificacoesinteligentes.com/leads/?phone=%2B${phoneFormated}`,
      "_blank"
    );
  }
}



