import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { NgxUsefulSwiperModule } from "ngx-useful-swiper";
import { HomeSliderComponent } from "./home-slider/home-slider.component";
import { MarketplaceProductsSliderComponent } from "./marketplace-products-slider/marketplace-products-slider.component";
import { MarketplaceAllSliderComponent } from "./marketplace-all-slider/marketplace-all-slider.component";

@NgModule({
  declarations: [
    HomeSliderComponent,
    MarketplaceProductsSliderComponent,
    MarketplaceAllSliderComponent,
  ],
  imports: [CommonModule, NgxUsefulSwiperModule],
  exports: [
    HomeSliderComponent,
    MarketplaceProductsSliderComponent,
    MarketplaceAllSliderComponent,
    NgxUsefulSwiperModule,
  ],
})
export class SliderModule {}
