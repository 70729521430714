import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import Api from "app/helpers/api";
import { Swiper, SwiperOptions } from "swiper";

type BannerResponseType = {
  active: boolean;
  id: number;
  url: string | null;
  imgDesktop: string | null;
  imgMobile: string | null;
  name: string | null;
  group: string | null;
  device: string | null;
  createdAt: string;
  updatedAt: string | null;
  deletedAt: string | null;
};

@Component({
  selector: "app-home-slider",
  templateUrl: "./home-slider.component.html",
  styleUrls: ["./home-slider.component.scss"],
})
export class HomeSliderComponent implements OnInit, AfterViewInit, OnDestroy {
  apiCall = this.api.new();

  bannersList: BannerResponseType[] = [];

  bannersTest: Partial<BannerResponseType>[] = [
    {
      imgDesktop:
        "https://imagens.ebc.com.br/DMhQOkuTj3PH5cjqd5C-CCWmnmM=/1170x700/smart/https://agenciabrasil.ebc.com.br/sites/default/files/thumbnails/image/2024/03/26/53612530507_2a09832e06_o.jpg?itok=twdsCsy9",
      imgMobile:
        "https://www.otempo.com.br/image/contentid/policy:1.3353948:1711230510/000-34M876B-jpg.jpg?f=3x2&w=1224",
      url: "https://www.google.com.br",
    },
    {
      imgDesktop:
        "https://www.otempo.com.br/image/contentid/policy:1.3353948:1711230510/000-34M876B-jpg.jpg?f=3x2&w=1224",
      imgMobile:
        "https://www.otempo.com.br/image/contentid/policy:1.3353948:1711230510/000-34M876B-jpg.jpg?f=3x2&w=1224",
      url: "https://www.google.com.br",
    },
    {
      imgDesktop:
        "https://alcodesbase.blob.core.windows.net/generic/2021-05-11/20210511T135312143.png",
      imgMobile:
        "https://www.otempo.com.br/image/contentid/policy:1.3353948:1711230510/000-34M876B-jpg.jpg?f=3x2&w=1224",
      url: "https://www.google.com.br",
    },
    {
      imgDesktop: "https://picsum.photos/200/300",
      imgMobile:
        "https://www.otempo.com.br/image/contentid/policy:1.3353948:1711230510/000-34M876B-jpg.jpg?f=3x2&w=1224",
      url: "https://www.google.com.br",
    },
  ];

  swiperInterval: any;

  swiperInit = false;

  swiperConfig: SwiperOptions = {
    spaceBetween: 30,
    centeredSlides: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    on: {
      init: () => {
        this.swiperInit = true;
      },
    },
  };

  @ViewChild("swiperGallery", { static: false }) swiperGallery: {
    swiper: Swiper;
  };

  constructor(private api: Api) {}

  ngOnInit(): void {
    this.apiCall
      .get<BannerResponseType[] | null>("banners/seller")
      .subscribe((response) => {
        if (response.return) {
          const banners = response.return?.filter((b) => b.active);

          this.bannersList = banners || [];

          if (this.swiperInit) {
            this.swiperGallery.swiper.update();
          }
        }
      });
  }

  ngAfterViewInit(): void {
    this.initSliderPooling();
  }

  initSliderPooling(): void {
    this.swiperInterval = setInterval(() => {
      if (
        this.swiperGallery.swiper.activeIndex ===
        this.bannersList.length - 1
      ) {
        this.swiperGallery.swiper.slideTo(0);
        return;
      }
      this.swiperGallery.swiper.slideNext();
    }, 6800);
  }

  goPrev(): void {
    this.ngOnDestroy();

    if (this.swiperGallery.swiper.activeIndex === 0) {
      this.swiperGallery.swiper.slideTo(this.bannersList.length - 1);
      return;
    }
    this.swiperGallery.swiper.slidePrev();

    setTimeout(() => {
      this.initSliderPooling();
    }, 200);
  }

  goNext(): void {
    this.ngOnDestroy();

    if (this.swiperGallery.swiper.activeIndex === this.bannersList.length - 1) {
      this.swiperGallery.swiper.slideTo(0);
      return;
    }
    this.swiperGallery.swiper.slideNext();

    setTimeout(() => {
      this.initSliderPooling();
    }, 200);
  }

  ngOnDestroy(): void {
    if (!this.swiperInterval) return;
    clearInterval(this.swiperInterval);
  }

  onClickElement(link: string) {
    window.open(link, "_blank");
  }
}
