import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SocialAuthService } from "angularx-social-login";
import * as cep from "cep-promise";
import * as CryptoJS from "crypto-js";
import * as moment from "moment";
import { DragulaService } from "ng2-dragula";
import {
  CountryISO,
  PhoneNumberFormat,
  SearchCountryField,
} from "ngx-intl-tel-input";
import { NgxSpinnerService } from "ngx-spinner";
import { WebcamImage } from "ngx-webcam";
import { Observable, Subject } from "rxjs";
import { take } from "rxjs/operators";
import Swal from "sweetalert2";
import Api from "../../../helpers/api";
import App from "../../../helpers/app";
import Me from "../../../helpers/me";
import { AuthService } from "../../../shared/auth/auth.service";
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
@Component({
  selector: "app-finance-account",
  templateUrl: "./finance-account.component.html",
  styleUrls: ["./finance-account.component.scss"],
})
export class FinanceAccountComponent implements OnInit {
  @ViewChild("contentVideo") contentVideo: TemplateRef<any>;

  public webcamImage: WebcamImage = null;
  private trigger: Subject<void> = new Subject<void>();
  apiCall = this.api.new().silent();
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];

  user = null;
  account = {
    isPF: true,
    pj_cnpj: null,
    pj_nome: null,
    pj_cpf_responsavel: null,
    pj_nome_responsavel: null,
    pf_cpf: null,
    pf_nome: null,
    physical_products: false,
    endereco: null,
    bairro: null,
    cep: null,
    cidade: null,
    estado: null,
    telefone: null,
    banco: null,
    agencia: null,
    digito_agencia: null,
    tipo_conta: null,
    conta: null,
    digito_conta: null,
    selfie_image: null,
    document_front: null,
    address_proof: null,
    verification_status: "",
  };
  iuguAccount = {
    id: null,
    recebimento: null,
    tipo: null,
    nome: null,
    cpf: null,
    cnpj: null,
    telephone: null,
    cep: null,
    company_name: null,
    resp_cpf: null,
    resp_name: null,
    physical_products: null,
    bank_name: null,
    bank_account_type: null,
    bank_agency: null,
    bank_account: null,
    endereco: null,
    bairro: null,
    cidade: null,
    estado: null,
    banco: null,
    agencia: null,
    conta: null,
    tipo_conta: null,
    can_receive: null,
    person_type: null,
    person_name: null,
    verification_status: "",
  };
  bancos = [
    { banco: "Itaú", codigo: "341" },
    { banco: "Bradesco", codigo: "237" },
    { banco: "Caixa Econômica", codigo: "104" },
    { banco: "Banco do Brasil", codigo: "001" },
    { banco: "Santander", codigo: "033" },
    { banco: "Banrisul", codigo: "041" },
    { banco: "Sicredi", codigo: "748" },
    { banco: "Sicoob", codigo: "756" },
    { banco: "Inter", codigo: "077" },
    { banco: "BRB", codigo: "070" },
    { banco: "Via Credi", codigo: "085" },
    { banco: "Neon", codigo: "655" },
    { banco: "Votorantim", codigo: "655" },
    { banco: "Nubank", codigo: "260" },
    { banco: "Pagseguro", codigo: "290" },
    { banco: "Banco Original", codigo: "212" },
    { banco: "Safra", codigo: "422" },
    { banco: "Modal", codigo: "746" },
    { banco: "Banestes", codigo: "021" },
    { banco: "Unicred", codigo: "136" },
    { banco: "Money Plus", codigo: "274" },
    { banco: "Mercantil do Brasil", codigo: "389" },
    { banco: "JP Morgan", codigo: "376" },
    { banco: "Gerencianet Pagamentos do Brasil", codigo: "364" },
    { banco: "Banco C6", codigo: "336" },
    { banco: "BS2", codigo: "218" },
    { banco: "Banco Topazio", codigo: "082" },
    { banco: "Uniprime", codigo: "099" },
    { banco: "Stone", codigo: "197" },
    { banco: "Banco Daycoval", codigo: "707" },
    { banco: "Rendimento", codigo: "633" },
    { banco: "Banco do Nordeste", codigo: "004" },
    { banco: "Citibank", codigo: "745" },
    { banco: "PJBank", codigo: "301" },
    {
      banco: "Cooperativa Central de Credito Noroeste Brasileiro",
      codigo: "97",
    },
    { banco: "Uniprime Norte do Paraná", codigo: "084" },
    { banco: "Global SCM", codigo: "384" },
    { banco: "Next", codigo: "237" },
    { banco: "Cora", codigo: "403" },
    { banco: "Mercado Pago", codigo: "323" },
    { banco: "Banco da Amazonia", codigo: "003" },
    { banco: "BNP Paribas Brasil", codigo: "752" },
    { banco: "Juno", codigo: "383" },
    { banco: "Cresol", codigo: "133" },
    { banco: "BRL Trust DTVM", codigo: "173" },
    { banco: "Banco Banese", codigo: "047" },
    { banco: "Banco BTG Pactual", codigo: "208" },
    { banco: "Banco Omni", codigo: "613" },
  ];
  accountDocuments: any = [];
  requestedDocuments: any[] = [];
  estados = [
    "AC",
    "AL",
    "AP",
    "AM",
    "BA",
    "CE",
    "DF",
    "ES",
    "GO",
    "MA",
    "MT",
    "MS",
    "MG",
    "PA",
    "PB",
    "PR",
    "PE",
    "PI",
    "RJ",
    "RN",
    "RS",
    "RO",
    "RR",
    "SC",
    "SP",
    "SE",
    "TO",
  ];
  tipoconta = [
    { descricao: "Corrente", codigo: "cc" },
    { descricao: "Poupança", codigo: "cp" },
  ];
  identityFrontImage = null;
  identityFrontImageURL = null;
  identityFrontImageSHA1 = null;
  identityBackImage = null;
  identityBackImageURL = null;
  identityBackImageSHA1 = null;
  selfieImage = null;
  selfieImageURL = null;
  selfieImageSHA1 = null;
  blockAgencyDigit = null;
  blockAccountDigit = null;
  subscription = null;
  videoUrl = null;
  selectedDocument: any = null;
  selfieModalRef: NgbModalRef | null = null;
  addressProofModalRef: NgbModalRef | null = null;
  identityModalRef: NgbModalRef | null = null;
  constructor(
    private modalService: NgbModal,
    private router: Router,
    private authService: AuthService,
    private dragulaService: DragulaService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private _sanitizer: DomSanitizer,
    private me: Me,
    private socialAuthService: SocialAuthService,
    private app: App,
    private api: Api
  ) {
    this.me
      .get()
      .pipe(take(1))
      .toPromise()
      .then((user) => {
        this.user = user;
      });
  }
  async ngOnInit() {
    this.resetAccount();
    this.resetIuguAccount();
    await this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    this.videoUrl = this._sanitizer.bypassSecurityTrustResourceUrl(
      "https://www.youtube.com/embed/R3Xq-qy_w5s"
    );
    this.getAccount();
    const subAccount = await this.apiCall
      .get("seller/me/subconta")
      .pipe(take(1))
      .toPromise();
    const subscription = await this.apiCall
      .get("seller/me/verify/subscription/plataforma")
      .pipe(take(1))
      .toPromise();

    if (subscription && subscription.return) {
      this.subscription = subscription.return;
    }

    if (
      this.subscription &&
      this.subscription.plano_ativo == false &&
      this.user.utmSource == "CAPTACAO_PARCEIROS"
    ) {
      this.subscription.plano_ativo = true;
    }

    if (subAccount && subAccount.return) {
      this.iuguAccount = subAccount.return;
    } else {
      this.OpenModalXl(this.contentVideo);
    }

    await this.spinner.hide();
  }

  getAccount() {
    this.apiCall.get("seller/me/marketplaceaccountlocal").subscribe((data) => {
      const documents = data.return?.SubAccountDocumentControl || [];
      this.requestedDocuments = documents.filter(
        (doc) => doc.statusType === "REQUESTED"
      );
    });
  }

  openDocumentModal(document: any) {
    console.log("Abrindo modal para documento:", document);
    this.selectedDocument = document;
  }

  formatDate(date) {
    moment.locale("pt-br");
    return moment(date).format("DD/MM/YYYY HH:mm:ss");
  }
  isCPF = true;
  switchTypeAccount(isCPF) {
    if (!isCPF)
      this.app.alert(
        "Importante",
        "A sua conta bancária deverá ser uma conta pessoa jurídica cadastrada em seu CNPJ!",
        "info"
      );
    this.resetAccount();
    this.isCPF = isCPF;
    this.account.isPF = this.isCPF;
  }
  openModal(template) {
    const modalRef = this.modalService.open(template);
  }

  closeModal(modalType: "selfie" | "addressProof" | "identity") {
    if (modalType === "selfie" && this.selfieModalRef) {
      this.selfieModalRef.close();
    } else if (modalType === "addressProof" && this.addressProofModalRef) {
      this.addressProofModalRef.close();
    } else if (modalType === "identity" && this.identityModalRef) {
      this.identityModalRef.close();
    }
  }

  OpenModalXl(content) {
    this.modalService.open(content, { centered: true, size: "xl" });
  }

  OpenModalNormal(content) {
    this.modalService.open(content, { centered: true });
  }

  getMaskAgencia(fromCode) {
    const self = this;
    if (!self.account.banco) return "0*-9*";
    let mask = "";

    let banco = !fromCode
      ? self.account.banco
      : self.bancos.find((x) => x.codigo == self.account.banco)
      ? self.bancos.find((x) => x.codigo == self.account.banco).banco
      : null;
    if (!banco) return "0*-9*";

    switch (banco) {
      case "Money Plus":
        mask = "0";
        break;
      case "BNP Paribas Brasil":
      case "BRL Trust DTVM":
      case "Banco Banese":
        mask = "000";
        break;
      case "Santander":
      case "Caixa Econômica":
      case "Itaú":
      case "Agibank":
      case "Banpará":
      case "Banrisul":
      case "Sicredi":
      case "Sicoob (Bancoob)":
      case "Inter":
      case "BRB":
      case "Via Credi":
      case "Neon":
      case "Votorantim":
      case "Nubank":
      case "Pagseguro":
      case "Banco Original":
      case "Safra":
      case "Modal":
      case "Banestes":
      case "Unicred":
      case "Mercantil do Brasil":
      case "JP Morgan":
      case "Gerencianet Pagamentos do Brasil":
      case "Banco C6":
      case "BS2":
      case "Banco Topazio":
      case "Uniprime":
      case "Stone":
      case "Banco Daycoval":
      case "Citibank":
      case "PJBank":
      case "Cooperativa Central de Credito Noroeste Brasileiro":
      case "Uniprime Norte do Paraná":
      case "Global SCM":
      case "Cora":
      case "Mercado Pago":
      case "Banco da Amazonia":
      case "Juno":
      case "Banco BTG Pactual":
      case "Banco Omni":
        mask = "0000";
        break;
      case "Banco do Brasil":
      case "Bradesco":
      case "Next":
      case "Rendimento":
      case "Cresol":
        mask = "0000-0";
        break;
      default:
        mask = "0*-9*";
        break;
    }
    return mask;
  }

  getMaskConta(fromCode) {
    const self = this;
    if (!self.account.banco) return "0*-9*";
    let mask = "";

    let banco = !fromCode
      ? self.account.banco
      : self.bancos.find((x) => x.codigo == self.account.banco)
      ? self.bancos.find((x) => x.codigo == self.account.banco).banco
      : null;
    if (!banco) return "0*-9*";

    switch (banco) {
      case "Citibank":
        mask = "00000000";
        break;
      case "Agibank":
      case "Rendimento":
        mask = "0000000000";
        break;
      case "Global SCM":
        mask = "00000000000";
        break;
      case "Itaú":
      case "Banco Topazio":
      case "Uniprime":
      case "Banco BTG Pactual":
      case "Cresol":
        mask = "00000-0";
        break;
      case "Banco do Nordeste":
      case "BRL Trust DTVM":
      case "BS2":
      case "Banco Daycoval":
      case "Uniprime Norte do Paraná":
      case "Banco da Amazonia":
      case "Banco Omni":
        mask = "000000-0";
        break;
      case "BNP Paribas Brasil":
        mask = "000000-000";
        break;
      case "Banco Original":
      case "Banco C6":
      case "Stone":
      case "Cooperativa Central de Credito Noroeste Brasileiro":
      case "Cora":
      case "Bradesco":
      case "Next":
        mask = "0000000-0";
        break;
      case "Money Plus":
      case "Banco Banese":
      case "Santander":
      case "Pagseguro":
      case "Safra":
      case "Banestes":
      case "Unicred":
      case "Mercantil do Brasil":
      case "Gerencianet Pagamentos do Brasil":
      case "Banco do Brasil":
        mask = "00000000-0";
        break;
      case "Banpará":
      case "Banrisul":
      case "Sicoob (Bancoob)":
      case "Inter":
      case "BRB":
      case "Neon":
      case "Votorantim":
      case "Modal":
        mask = "000000000-0";
        break;
      case "Nubank":
      case "PJBank":
      case "Juno":
        mask = "0000000000-0";
        break;
      case "Via Credi":
      case "JP Morgan":
        mask = "00000000000-0";
        break;
      case "Mercado Pago":
        mask = "0000000000000-0";
        break;
      case "Sicredi":
        mask = "000000D";
        break;
      case "Caixa Econômica":
        mask = "0*-0";
        break;
      default:
        mask = "0*-9*";
        break;
    }
    return mask;
  }

  resetAccount() {
    this.account = {
      isPF: true,
      pj_cnpj: null,
      pj_nome: null,
      pj_cpf_responsavel: null,
      pj_nome_responsavel: null,
      pf_cpf: null,
      pf_nome: null,
      physical_products: null,
      endereco: null,
      bairro: null,
      cep: null,
      cidade: null,
      estado: null,
      telefone: null,
      banco: null,
      agencia: null,
      digito_agencia: null,
      tipo_conta: null,
      conta: null,
      digito_conta: null,
      selfie_image: null,
      document_front: null,
      address_proof: null,
      verification_status: "",
    };
  }

  resetIuguAccount() {
    this.iuguAccount = {
      id: null,
      recebimento: null,
      tipo: null,
      nome: null,
      cpf: null,
      cnpj: null,
      telephone: null,
      cep: null,
      company_name: null,
      resp_cpf: null,
      resp_name: null,
      physical_products: null,
      bank_name: null,
      bank_account_type: null,
      bank_agency: null,
      bank_account: null,
      endereco: null,
      bairro: null,
      cidade: null,
      estado: null,
      banco: null,
      agencia: null,
      conta: null,
      tipo_conta: null,
      can_receive: null,
      person_type: null,
      person_name: null,
      verification_status: "",
    };
  }

  consultaCep() {
    const self = this;
    if (self.account.cep) {
      self.spinner.show(undefined, {
        type: "ball-triangle-path",
        size: "medium",
        bdColor: "rgba(0, 0, 0, 0.8)",
        color: "#fff",
        fullScreen: true,
      });
      cep(self.account.cep)
        .then((local) => {
          console.log("pesso", local);
          self.spinner.hide();
          self.account.endereco = local.street;
          self.account.estado = local.state;
          self.account.bairro = local.neighborhood;
          self.account.cidade = local.city;
        })
        .catch((erro) => {
          self.spinner.hide();
        });
    }
  }

  async updateBank(modal) {
    if (
      !this.account ||
      !this.account.banco ||
      !this.account.conta ||
      !this.account.agencia ||
      !this.account.tipo_conta
    )
      return;
    let accountToSend = { ...this.account };
    accountToSend.conta = this.account.conta + "-" + this.account.digito_conta;
    if (this.account.digito_agencia)
      accountToSend.agencia =
        this.account.agencia + "-" + this.account.digito_agencia;

    await this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    const submit = await this.apiCall
      .post("seller/me/subconta/bancario", accountToSend)
      .pipe(take(1))
      .toPromise();
    await this.spinner.hide();
    if (submit.success) {
      await this.app.alert(
        "Tudo certo!",
        "Seus dados bancários foram atualizados!",
        "success"
      );
      modal.close("Close click");
      await this.ngOnInit();
    } else {
      await this.app.alert("Ops :(", submit.message, "error");
    }
  }
  async submitAccount(modal) {
    const self = this;
    console.log("Dados enviados:", this.account);
    console.log("Dados enviados:", this.accountDocuments);
    if (
      !this.account ||
      !this.account.banco ||
      !this.account.conta ||
      !this.account.agencia ||
      !this.account.tipo_conta ||
      !this.account.endereco ||
      !this.account.cidade ||
      !this.account.estado ||
      !this.account.cep ||
      (!this.account.pf_cpf && !this.account.pj_cnpj)
    )
      return;
    let accountToSend = { ...this.account };
    accountToSend.conta = this.account.conta + "-" + this.account.digito_conta;
    accountToSend.telefone = this.account.telefone.e164Number;
    if (this.account.digito_agencia)
      accountToSend.agencia =
        this.account.agencia + "-" + this.account.digito_agencia;

    await this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    if (this.identityFrontImage) {
      const dataUrl = await self.apiCall
        .get("blackbaze/urltoupload")
        .pipe(take(1))
        .toPromise();
      if (!dataUrl.return) {
        self.spinner.hide();
        return;
      }
      if (
        dataUrl.return &&
        dataUrl.return.authorizationToken &&
        dataUrl.return.uploadUrl &&
        dataUrl.return.uniqid
      ) {
        var optionsB2 = {
          headers: {
            Authorization: dataUrl.return.authorizationToken,
            "Content-Type": this.identityFrontImage.type,
            "X-Bz-File-Name": dataUrl.return.uniqid.toString() + ".jpeg",
            "X-Bz-Content-Sha1": this.identityFrontImageSHA1,
          },
        };
        const returnedUpload = await self.api.http
          .post(dataUrl.return.uploadUrl, this.identityFrontImage, optionsB2)
          .toPromise();
        if (returnedUpload)
          self.identityFrontImageURL =
            "https://files.wecodde.com/file/wecodde/" +
            JSON.parse(JSON.stringify(returnedUpload)).fileName;
      }

      accountToSend.document_front = self.identityFrontImageURL;
    }

    if (this.identityBackImage) {
      const dataUrl = await self.apiCall
        .get("blackbaze/urltoupload")
        .pipe(take(1))
        .toPromise();
      if (!dataUrl.return) {
        self.spinner.hide();
        return;
      }
      if (
        dataUrl.return &&
        dataUrl.return.authorizationToken &&
        dataUrl.return.uploadUrl &&
        dataUrl.return.uniqid
      ) {
        var optionsB2 = {
          headers: {
            Authorization: dataUrl.return.authorizationToken,
            "Content-Type": this.identityBackImage.type,
            "X-Bz-File-Name": dataUrl.return.uniqid.toString() + ".jpeg",
            "X-Bz-Content-Sha1": this.identityBackImageSHA1,
          },
        };
        const returnedUpload = await self.api.http
          .post(dataUrl.return.uploadUrl, this.identityBackImage, optionsB2)
          .toPromise();
        if (returnedUpload)
          self.identityBackImageURL =
            "https://files.wecodde.com/file/wecodde/" +
            JSON.parse(JSON.stringify(returnedUpload)).fileName;
      }

      accountToSend.address_proof = self.identityBackImageURL;
    }

    if (this.selfieImage) {
      const dataUrl = await self.apiCall
        .get("blackbaze/urltoupload")
        .pipe(take(1))
        .toPromise();
      if (!dataUrl.return) {
        self.spinner.hide();
        return;
      }
      if (
        dataUrl.return &&
        dataUrl.return.authorizationToken &&
        dataUrl.return.uploadUrl &&
        dataUrl.return.uniqid
      ) {
        var optionsB2 = {
          headers: {
            Authorization: dataUrl.return.authorizationToken,
            "Content-Type": this.selfieImage.type,
            "X-Bz-File-Name": dataUrl.return.uniqid.toString() + ".jpeg",
            "X-Bz-Content-Sha1": this.selfieImageSHA1,
          },
        };
        const returnedUpload = await self.api.http
          .post(dataUrl.return.uploadUrl, this.selfieImage, optionsB2)
          .toPromise();
        if (returnedUpload)
          self.selfieImageURL =
            "https://files.wecodde.com/file/wecodde/" +
            JSON.parse(JSON.stringify(returnedUpload)).fileName;
      }

      accountToSend.selfie_image = self.selfieImageURL;
    }

    const submit = await this.apiCall
      .post("v2/seller/me/subconta", accountToSend)
      .pipe(take(1))
      .toPromise();
    await this.spinner.hide();
    if (submit.success) {
      await this.app.alert(
        "Tudo certo!",
        "Sua conta foi cadastrada com sucesso, iremos analisar os dados!",
        "success"
      );
      modal.close("Close click");
      await this.ngOnInit();
    } else {
      await this.app.alert("Ops :(", submit.message, "error");
    }
  }

  verifyAccount(modal) {
    const self = this;

    //console.log(this.iuguAccount)

    if (this.iuguAccount.person_type == 0) {
      this.isCPF = true;
      this.switchTypeAccount(this.isCPF);
      this.account.pf_cpf = this.iuguAccount.cpf;
      self.account.pf_nome = this.iuguAccount.person_name;
    } else if (this.iuguAccount.person_type == 1) {
      this.isCPF = false;
      this.switchTypeAccount(this.isCPF);
      self.account.pj_cnpj = this.iuguAccount.cnpj;
      self.account.pj_nome = this.iuguAccount.company_name;
      self.account.pj_cpf_responsavel = this.iuguAccount.resp_cpf;
      self.account.pj_nome_responsavel = this.iuguAccount.resp_name;
    }

    self.account.physical_products = this.iuguAccount.physical_products;
    self.account.cep = this.iuguAccount.cep;
    this.consultaCep();
    self.account.telefone = this.iuguAccount.telephone;
    self.account.banco = this.iuguAccount.bank_name;
    self.account.tipo_conta = this.iuguAccount.bank_account_type;
    self.account.agencia = this.iuguAccount.bank_agency.split("-")[0]
      ? this.iuguAccount.bank_agency.split("-")[0]
      : this.iuguAccount.bank_agency;
    self.account.digito_agencia = this.iuguAccount.bank_agency.split("-")[1]
      ? this.iuguAccount.bank_agency.split("-")[1]
      : null;
    self.account.conta = this.iuguAccount.bank_account.split("-")[0]
      ? this.iuguAccount.bank_account.split("-")[0]
      : null;
    self.account.digito_conta = this.iuguAccount.bank_account.split("-")[1]
      ? this.iuguAccount.bank_account.split("-")[1]
      : null;

    console.log("Banco", self.account.banco);

    const modalRef = this.modalService.open(modal);
  }

  async fileToBase64(file) {
    return await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  async selecionarImagemFrenteDocumento(modalRef) {
    const self = this;
    let { value: file } = await Swal.fire({
      title: "Imagem do Documento",
      input: "file",
      showCancelButton: true,
      cancelButtonText: `Cancelar`,
      confirmButtonText: `Salvar`,
      inputAttributes: {
        accept: "image/*",
        "aria-label": "Faça upload de uma imagem.",
      },
      inputValidator: async (value) => {
        if (!value) {
          return "Você precisa escolher uma imagem!";
        }
        // @ts-ignore
        let file: File = value;

        if (file.size > 30000000) {
          return "São permitidos imagens de até 30MB!";
        }
        if (
          file.type != "image/png" &&
          file.type != "image/jpg" &&
          file.type != "image/jpeg"
        ) {
          return "São permitidos apenas imagens!";
        }
        self.identityFrontImage = file;
        const base64 = await self.fileToBase64(file);
        if (base64) self.identityFrontImageURL = base64;

        var reader = new FileReader(); //define a Reader
        reader.onload = function (f) {
          var file_result = this.result; // this == reader, get the loaded file "result"
          var file_wordArr = CryptoJS.lib.WordArray.create(
            file_result as ArrayBuffer
          ); //convert blob to WordArray , see https://code.google.com/p/crypto-js/issues/detail?id=67
          var sha1_hash = CryptoJS.SHA1(file_wordArr); //calculate SHA1 hash
          self.identityFrontImageSHA1 = sha1_hash.toString();
        };
        reader.readAsArrayBuffer(file); //read file as ArrayBuffer
        modalRef.close();
      },
    });
  }

  async selecionarImagemVersoDocumento(modalRef) {
    const self = this;
    let { value: file } = await Swal.fire({
      title: "Imagem  Comprovante Residência",
      input: "file",
      showCancelButton: true,
      cancelButtonText: `Cancelar`,
      confirmButtonText: `Salvar`,
      inputAttributes: {
        accept: "image/*",
        "aria-label": "Faça upload de uma imagem.",
      },
      inputValidator: async (value) => {
        if (!value) {
          return "Você precisa escolher uma imagem!";
        }
        // @ts-ignore
        let file: File = value;

        if (file.size > 30000000) {
          return "São permitidos imagens de até 30MB!";
        }
        if (
          file.type != "image/png" &&
          file.type != "image/jpg" &&
          file.type != "image/jpeg"
        ) {
          return "São permitidos apenas imagens!";
        }
        self.identityBackImage = file;
        const base64 = await self.fileToBase64(file);
        if (base64) self.identityBackImageURL = base64;

        var reader = new FileReader(); //define a Reader
        reader.onload = function (f) {
          var file_result = this.result; // this == reader, get the loaded file "result"
          var file_wordArr = CryptoJS.lib.WordArray.create(
            file_result as ArrayBuffer
          ); //convert blob to WordArray , see https://code.google.com/p/crypto-js/issues/detail?id=67
          var sha1_hash = CryptoJS.SHA1(file_wordArr); //calculate SHA1 hash
          self.identityBackImageSHA1 = sha1_hash.toString();
        };
        reader.readAsArrayBuffer(file); //read file as ArrayBuffer
        modalRef.close();
      },
    });
  }

  async handleImage(webcamImage: WebcamImage, modal) {
    const self = this;
    let file = this.dataURItoBlob(webcamImage.imageAsBase64);
    modal.close("Close click");
    this.webcamImage = webcamImage;

    self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    this.selfieImage = file;
    const base64 = await self.fileToBase64(file);
    if (base64) self.selfieImageURL = base64;

    var reader = new FileReader(); //define a Reader
    reader.onload = function (f) {
      var file_result = this.result; // this == reader, get the loaded file "result"
      var file_wordArr = CryptoJS.lib.WordArray.create(
        file_result as ArrayBuffer
      ); //convert blob to WordArray , see https://code.google.com/p/crypto-js/issues/detail?id=67
      var sha1_hash = CryptoJS.SHA1(file_wordArr); //calculate SHA1 hash
      self.selfieImageSHA1 = sha1_hash.toString();
    };
    reader.readAsArrayBuffer(file);
    self.spinner.hide();
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: "image/jpeg" });
    return blob;
  }

  public triggerSnapshot(): void {
    this.trigger.next();
  }

  async resendIdentityVerification(modal) {
    const self = this;

    let accountValidation = {
      document_front: null,
      address_proof: null,
      selfie_image: null,
    };

    await this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    if (this.identityFrontImage) {
      const dataUrl = await self.apiCall
        .get("blackbaze/urltoupload")
        .pipe(take(1))
        .toPromise();
      console.log("URL", dataUrl);
      if (!dataUrl.return) {
        self.spinner.hide();
        return;
      }
      if (
        dataUrl.return &&
        dataUrl.return.authorizationToken &&
        dataUrl.return.uploadUrl &&
        dataUrl.return.uniqid
      ) {
        var optionsB2 = {
          headers: {
            Authorization: dataUrl.return.authorizationToken,
            "Content-Type": this.identityFrontImage.type,
            "X-Bz-File-Name": dataUrl.return.uniqid.toString() + ".jpeg",
            "X-Bz-Content-Sha1": this.identityFrontImageSHA1,
          },
        };
        const returnedUpload = await self.api.http
          .post(dataUrl.return.uploadUrl, this.identityFrontImage, optionsB2)
          .toPromise();
        if (returnedUpload)
          self.identityFrontImageURL =
            "https://files.wecodde.com/file/wecodde/" +
            JSON.parse(JSON.stringify(returnedUpload)).fileName;
      }

      accountValidation.document_front = self.identityFrontImageURL;
    }

    if (this.identityBackImage) {
      const dataUrl = await self.apiCall
        .get("blackbaze/urltoupload")
        .pipe(take(1))
        .toPromise();
      console.log("URL", dataUrl);
      if (!dataUrl.return) {
        self.spinner.hide();
        return;
      }
      if (
        dataUrl.return &&
        dataUrl.return.authorizationToken &&
        dataUrl.return.uploadUrl &&
        dataUrl.return.uniqid
      ) {
        var optionsB2 = {
          headers: {
            Authorization: dataUrl.return.authorizationToken,
            "Content-Type": this.identityBackImage.type,
            "X-Bz-File-Name": dataUrl.return.uniqid.toString() + ".jpeg",
            "X-Bz-Content-Sha1": this.identityBackImageSHA1,
          },
        };
        const returnedUpload = await self.api.http
          .post(dataUrl.return.uploadUrl, this.identityBackImage, optionsB2)
          .toPromise();
        if (returnedUpload)
          self.identityBackImageURL =
            "https://files.wecodde.com/file/wecodde/" +
            JSON.parse(JSON.stringify(returnedUpload)).fileName;
      }

      accountValidation.address_proof = self.identityBackImageURL;
    }

    if (this.selfieImage) {
      const dataUrl = await self.apiCall
        .get("blackbaze/urltoupload")
        .pipe(take(1))
        .toPromise();
      console.log("URL", dataUrl);
      if (!dataUrl.return) {
        self.spinner.hide();
        return;
      }
      if (
        dataUrl.return &&
        dataUrl.return.authorizationToken &&
        dataUrl.return.uploadUrl &&
        dataUrl.return.uniqid
      ) {
        var optionsB2 = {
          headers: {
            Authorization: dataUrl.return.authorizationToken,
            "Content-Type": this.selfieImage.type,
            "X-Bz-File-Name": dataUrl.return.uniqid.toString() + ".jpeg",
            "X-Bz-Content-Sha1": this.selfieImageSHA1,
          },
        };
        const returnedUpload = await self.api.http
          .post(dataUrl.return.uploadUrl, this.selfieImage, optionsB2)
          .toPromise();
        if (returnedUpload)
          self.selfieImageURL =
            "https://files.wecodde.com/file/wecodde/" +
            JSON.parse(JSON.stringify(returnedUpload)).fileName;
      }

      accountValidation.selfie_image = self.selfieImageURL;
    }

    const submit = await this.apiCall
      .put("marketplace/resend-docs", accountValidation)
      .pipe(take(1))
      .toPromise();
    await this.spinner.hide();
    if (submit.success) {
      await this.app.alert(
        "Tudo certo!",
        "Seus dados foram enviados, aguarde a validação!",
        "success"
      );
      modal.close("Close click");
      await this.ngOnInit();
    } else {
      await this.app.alert("Ops :(", submit.message, "error");
    }
  }

  verifyBankAgency() {
    const self = this;
    if (
      this.account.banco != "001" &&
      this.account.banco != "237" &&
      this.account.banco != "633" &&
      this.account.banco != "Banco do Brasil" &&
      this.account.banco != "Bradesco" &&
      this.account.banco != "Rendimento" &&
      this.account.banco != "Next"
    ) {
      this.blockAgencyDigit = true;
      this.account.digito_agencia = "";
    } else {
      this.blockAgencyDigit = false;
    }

    if (
      this.account.banco == "Rendimento" ||
      this.account.banco == "Global SCM" ||
      this.account.banco == "633" ||
      this.account.banco == "384"
    ) {
      this.blockAccountDigit = true;
      this.account.digito_conta = "";
    } else {
      this.blockAccountDigit = false;
    }
  }

  mapIdentityValidation(value: number | null): string | null {
    switch (value) {
      case 1:
        return "pending";
      case 2:
        return "rejected";
      case 3:
        return "accepted";
      default:
        return null;
    }
  }

  async takeSelfie(modalRef) {
    const self = this;

    let { value: file } = await Swal.fire({
      title: "Imagem Selfie",
      input: "file",
      showCancelButton: true,
      cancelButtonText: `Cancelar`,
      confirmButtonText: `Salvar`,
      inputAttributes: {
        accept: "image/*",
        "aria-label": "Faça upload de uma imagem.",
      },
      inputValidator: async (value) => {
        if (!value) {
          return "Você precisa escolher uma imagem!";
        }
        // @ts-ignore
        let file: File = value;

        if (file.size > 30000000) {
          return "São permitidos imagens de até 30MB!";
        }
        if (
          file.type !== "image/png" &&
          file.type !== "image/jpg" &&
          file.type !== "image/jpeg"
        ) {
          return "São permitidos apenas imagens!";
        }

        self.selfieImage = file;
        const base64 = await self.fileToBase64(file);
        if (base64) {
          self.selfieImageURL = base64;
        }

        const reader = new FileReader();
        reader.onload = function () {
          const fileResult = this.result as ArrayBuffer;
          const fileWordArr = CryptoJS.lib.WordArray.create(fileResult);
          const sha1Hash = CryptoJS.SHA1(fileWordArr);
          self.selfieImageSHA1 = sha1Hash.toString();
        };
        reader.readAsArrayBuffer(file);
        modalRef.close();
      },
    });
  }

  validateResendDocs() {
    const self = this;

    if (
      !this.identityFrontImage ||
      !this.identityBackImage ||
      !this.selfieImage
    ) {
      self.app.alert(
        "Ops :(",
        "Você precisa enviar a imagem do documento!",
        "error"
      );
      return;
    }
  }

  // Exemplo no componente TypeScript
  checkDocumentRequested(documentType: string): boolean {
    return this.requestedDocuments.some(
      (doc) =>
        doc.documentType === documentType && doc.statusType === "REQUESTED"
    );
  }

  areAllDocumentsSubmitted(): boolean {
    return this.requestedDocuments
      .filter((doc) => doc.statusType === "REQUESTED")
      .every((doc) => {
        switch (doc.documentType) {
          case "SELFIE":
            return !!this.selfieImageURL; // Verifica se a selfie foi enviada
          case "IDENTIFICATION":
            return !!this.identityFrontImageURL; // Verifica se o documento de identidade foi enviado
          case "ADDRESS_PROOF":
            return !!this.identityBackImage; // Verifica se o comprovante de endereço foi enviado
          default:
            return false; // Caso não reconheça o tipo de documento
        }
      });
  }

  translateVerificationStatus(status: string): string {
    switch (status) {
      case "pending":
        return "Pendente";
      case "rejected":
        return "Rejeitado";
      case "accepted":
        return "Aceito";
      default:
        return "Desconhecido";
    }
  }
}
