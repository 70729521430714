import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import Swiper, { SwiperOptions } from "swiper";

@Component({
  selector: "app-marketplace-subscription-slides",
  templateUrl: "./marketplace-subscription-slides.component.html",
  styleUrls: ["./marketplace-subscription-slides.component.scss"],
})
export class MarketplaceSubscriptionSlidesComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input("subscription") subscription: any[] = [];
  @Input("selectedColor") selectedColor: string;

  subscriptionsMatrix: any[] = [];
  expandedDescriptions: boolean[] = [];
  swiperInterval: any;
  isMobile: boolean = false;

  swiperInit = false;
  swiperConfig: SwiperOptions = {
    spaceBetween: 30,
    centeredSlides: true,
    autoHeight: true,
    autoplay: false,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },

    on: {
      init: () => {
        this.swiperInit = true;
        this.updateNavigation();
      },
      slideChange: () => {
        this.updateNavigation();
      },
    },
  };

  @ViewChild("swiperGallery", { static: false }) swiperGallery: {
    swiper: Swiper;
  };

  constructor(private spinner: NgxSpinnerService) {}

  ngOnInit(): void {
    this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    this.expandedDescriptions = this.subscriptionsMatrix.map(() => false);
    this.transformSubscriptions();
    window.addEventListener("resize", this.onResize.bind(this));
    this.spinner.hide();
  }
  toggleDescription(index: number): void {
    this.expandedDescriptions[index] = !this.expandedDescriptions[index];
    setTimeout(() => {
      if (this.swiperGallery && this.swiperGallery.swiper) {
        this.swiperGallery.swiper.updateAutoHeight(300);
      }
    }, 0);
  }
  getShortDescription(description: string): string {
    return description.length > 50
      ? description.substring(0, 50) + "..."
      : description;
  }

  onResize() {
    this.transformSubscriptions();
    this.checkWindowSize();
    setTimeout(() => {
      if (this.swiperGallery?.swiper) {
        this.swiperGallery.swiper.update();

        this.spinner.hide();
      }
    }, 0);
  }
  checkWindowSize(): void {
    this.isMobile = window.innerWidth <= 768;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["subscription"] && this.subscription.length) {
      this.transformSubscriptions();
      setTimeout(() => {
        if (this.swiperGallery?.swiper) {
          this.swiperGallery.swiper.update();
        }
        this.updateNavigation();
      }, 0);
    }
  }

  ngAfterViewInit(): void {}

  transformSubscriptions() {
    const transformedSubscriptions = this.subscription.map((sub, i) => ({
      imgDesktop: sub.cover || "https://via.placeholder.com/1170x700",
      url: sub.checkout_url || "#",
      title: sub.name,
      isFeatured: sub.isFeatured,
      value: sub.value_cents,
      Plans: sub.Plans.map((plan, i) => ({
        interval: plan.interval_type,
        cycles: plan.max_cycles,
      })),
      value_with_discount: sub.Plans[0]?.value_cents || null,
      discount_percent: sub.Plans[0]?.value_cents
        ? `-${(
            ((sub.value_cents - sub.Plans[0].value_cents) / sub.value_cents) *
            100
          ).toFixed(0)}%`
        : null,
      description: sub.description || "Sem descrição disponível",
    }));

    const slidesPerView = window.innerWidth <= 768 ? 1 : 3;
    this.subscriptionsMatrix = [];
    for (let i = 0; i < transformedSubscriptions.length; i += slidesPerView) {
      this.subscriptionsMatrix.push(
        transformedSubscriptions.slice(i, i + slidesPerView)
      );
    }
  }
  getIntervalTypeInPortuguese(intervalType: string): string {
    const translations: { [key: string]: string } = {
      day: "Dia",
      week: "Semana",
      months: "Mês",
      year: "Ano",
    };

    return translations[intervalType] || intervalType;
  }

  initSliderPooling(): void {
    this.swiperInterval = setInterval(() => {
      if (
        this.swiperGallery.swiper.activeIndex ===
        this.subscriptionsMatrix.length - 1
      ) {
        this.swiperGallery.swiper.slideTo(0);
        return;
      }
      this.swiperGallery.swiper.slideNext();
    }, 3800);
  }

  goPrev(): void {
    this.ngOnDestroy();

    if (this.swiperGallery.swiper.activeIndex === 0) {
      this.swiperGallery.swiper.slideTo(this.subscriptionsMatrix.length - 1);
      return;
    }
    this.swiperGallery.swiper.slidePrev();
  }

  goNext(): void {
    this.ngOnDestroy();

    if (
      this.swiperGallery.swiper.activeIndex ===
      this.subscriptionsMatrix.length - 1
    ) {
      this.swiperGallery.swiper.slideTo(0);
      return;
    }
    this.swiperGallery.swiper.slideNext();
  }

  goToIndex(index: number) {
    this.ngOnDestroy();

    this.swiperGallery.swiper.slideTo(index);
  }

  openCheckout(url: string) {
    window.open(url, "_blank");
  }

  integerToReal(val: number) {
    return Intl.NumberFormat("pt-Br", {
      style: "currency",
      currency: "BRL",
    }).format(val / 100);
  }

  ngOnDestroy(): void {
    window.removeEventListener("resize", this.onResize);

    if (!this.swiperInterval) return;
    clearInterval(this.swiperInterval);
  }

  updateNavigation() {
    if (!this.swiperGallery || !this.swiperGallery.swiper) return;

    const swiper = this.swiperGallery.swiper;
    const totalSlides = this.subscriptionsMatrix.reduce(
      (count, group) => count + group.length,
      0
    );
    const slidesPerView = (this.swiperConfig.slidesPerView as number) || 3;
    const totalPages = Math.ceil(totalSlides / slidesPerView);

    const navigationEnabled = totalPages > 1;

    const pagination = document.querySelector(".swiper-pagination");
    if (pagination) {
      const bullets = pagination.querySelectorAll(".swiper-pagination-bullet");
      bullets.forEach((bullet, index) => {
        bullet.classList.toggle(
          "swiper-pagination-bullet-active",
          index === 0 && !navigationEnabled
        );
      });
    }
  }
  calculateTextColor(bgColor: string): string {
    const color = bgColor.replace("#", "");

    const fullColor =
      color.length === 3
        ? color
            .split("")
            .map((c) => c + c)
            .join("")
        : color;

    const r = parseInt(fullColor.substring(0, 2), 16);
    const g = parseInt(fullColor.substring(2, 4), 16);
    const b = parseInt(fullColor.substring(4, 6), 16);

    // Convertendo a cor de referência (#ff6600) para valores RGB
    const referenceR = 255;
    const referenceG = 102;
    const referenceB = 0;

    // Comparação direta para verificar se a cor é mais escura ou igual a #ff6600
    if (
      r < referenceR ||
      (r === referenceR && g < referenceG) ||
      (r === referenceR && g === referenceG && b <= referenceB)
    ) {
      return "white";
    }

    return "grey";
  }
}
