<div class="row text-left">
  <div class="col-sm-12">
    <div class="d-flex justify-content-between">
      <h2 class="text-bold-300" style="padding-bottom: 1.5rem">
        Conta de Recebimento
      </h2>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-auto">
    <p class="sub-texto">
      Está com dúvida sobre como cadastrar sua conta, assista o video abaixo
      <a
        href="javascript:void(0)"
        class="text-warning pulsingButton"
        (click)="OpenModalXl(contentVideo)"
        style="text-decoration: underline; cursor: pointer"
      >
        Ver Vídeo Tutorial
      </a>
    </p>
  </div>
  <div class="col-12">
    <a
      class="text-bold-500 mt-2"
      style="margin-top: 20px; font-size: 1.1em"
      href="https://personaldigitalapp.gitbook.io/intermediacao-de-pagamentos/"
      target="_blank"
    >
      Taxas e Tarifas
    </a>
  </div>
</div>

<div
  class="row mb-2"
  *ngIf="
    (!iuguAccount.id || iuguAccount.id == null) &&
    subscription &&
    subscription.plano_ativo == true
  "
>
  <div class="col">
    <div class="float-right">
      <button
        type="button"
        class="btn btn-lg btn-warning mb-md-0"
        (click)="openModal(newAccount)"
      >
        Cadastrar Conta
      </button>
    </div>
  </div>
</div>
<div
  class="row"
  *ngIf="
    iuguAccount &&
    iuguAccount.verification_status &&
    iuguAccount.verification_status != 'accepted'
  "
>
  <div class="col">
    <div class="card">
      <div class="card-content">
        <div class="card-body">
          <div class="row mb-2">
            <div class="col-12">
              <h2 class="text-bold-300">
                <i class="fa fa-clock-o"></i> Verificação de Identidade
              </h2>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12">
              <h5
                *ngIf="
                  iuguAccount &&
                  iuguAccount.verification_status &&
                  iuguAccount.verification_status == 'pending'
                "
              >
                Sua documentação está em análise pelo setor financeiro, agora é
                só aguardar! Assim que sua conta for validada, nós te
                avisaremos.
              </h5>
              <p
                style="color: red; font-size: 1.3em; font-weight: 500"
                *ngIf="
                  iuguAccount &&
                  iuguAccount.verification_status &&
                  iuguAccount.verification_status == 'rejected'
                "
              >
                <i class="fa fa-times"></i>Ops! Ocorreu um problema ao validar a
                sua documentação:
              </p>
              <p
                style="font-size: 1.1em"
                *ngIf="
                  iuguAccount &&
                  iuguAccount.verification_status &&
                  iuguAccount.verification_status == 'rejected'
                "
              >
                <i class="fa fa-info-circle"></i>
                {{ iuguAccount.verification_feedback }}
              </p>
            </div>
          </div>
          <div
            class="row mb-2"
            *ngIf="
              iuguAccount &&
              iuguAccount.verification_status &&
              iuguAccount.verification_status == 'rejected'
            "
          >
            <div class="col">
              <div class="float-right">
                <button
                  type="button"
                  class="btn btn-lg btn-warning mb-md-0"
                  (click)="openModal(resendDocuments)"
                >
                  Reenviar Documentos
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row" *ngIf="iuguAccount.id != null">
  <div class="col">
    <div class="card">
      <div class="card-content">
        <div class="card-body">
          <div class="row mb-2">
            <div class="col-8">
              <h2 class="text-bold-300">
                <i class="fa fa-user"></i> Dados Pessoais
              </h2>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col">
              <b>Conta Apta para Recebimento:</b>
              {{ iuguAccount.can_receive ? "Sim" : "Não" }}
            </div>
          </div>
          <div class="row mb-2" *ngIf="!iuguAccount.can_receive">
            <div class="col-12 col-lg-3 mb-1">
              <b>Status Verificação:</b>
              {{ translateVerificationStatus(iuguAccount.verification_status) }}
            </div>
            <div
              class="col-12 col-lg-3"
              *ngIf="iuguAccount.verification_feedback"
            >
              <b>Motivo:</b>
              {{
                iuguAccount.verification_feedback
                  ? iuguAccount.verification_feedback
                  : ""
              }}
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-12 col-lg-3 mb-1">
              <b>Tipo:</b>
              {{
                iuguAccount.person_type == 0
                  ? "Pessoa Física"
                  : "Pessoa Jurídica"
              }}
            </div>
            <div class="col-12 col-lg-3 mb-1">
              <b>CPF/CNPJ:</b>
              {{
                iuguAccount.person_type == 0
                  ? iuguAccount.cpf
                  : iuguAccount.cnpj
              }}
            </div>
            <div class="col-12 col-lg-3 mb-1">
              <b>Nome:</b>
              {{
                iuguAccount.person_type == 0
                  ? iuguAccount.person_name
                  : iuguAccount.company_name
              }}
            </div>
            <div class="col-12 col-lg-3 mb-1">
              <b>Telefone:</b> {{ iuguAccount.telephone }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row" *ngIf="iuguAccount.id != null">
  <div class="col">
    <div class="card">
      <div class="card-content">
        <div class="card-body">
          <div class="row mb-2">
            <div class="col">
              <h2 class="text-bold-300"><i class="fa fa-home"></i> Endereço</h2>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12 col-lg-3 mb-1">
              <b>CEP:</b> {{ iuguAccount.cep }}
            </div>
            <div class="col-12 col-lg-3 mb-1">
              <b>Endereço:</b> {{ iuguAccount.address }}
            </div>
            <div class="col-12 col-lg-3 mb-1">
              <b>Cidade:</b> {{ iuguAccount.city }}
            </div>
            <div class="col-12 col-lg-3">
              <b>Estado:</b> {{ iuguAccount.state }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row" *ngIf="iuguAccount.id != null">
  <div class="col">
    <div class="card">
      <div class="card-content">
        <div class="card-body">
          <div class="row mb-2">
            <div class="col">
              <h2 class="text-bold-300">
                <i class="fa fa-bank"></i> Dados Bancários
              </h2>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col">
              <div class="float-right">
                <button
                  *ngIf="
                    iuguAccount &&
                    iuguAccount.verification_status &&
                    iuguAccount.verification_status == 'accepted'
                  "
                  type="button"
                  class="btn btn-lg btn-warning mb-md-0"
                  (click)="openModal(newBank)"
                >
                  Alterar Conta Bancária
                </button>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12 col-lg-3 mb-1">
              <b>Banco:</b> {{ iuguAccount.bank_name }}
            </div>
            <div class="col-12 col-lg-3 mb-1">
              <b>Tipo de Conta:</b> {{ iuguAccount.bank_account_type }}
            </div>
            <div class="col-12 col-lg-3 mb-1">
              <b>Agência:</b> {{ iuguAccount.bank_agency }}
            </div>
            <div class="col-12 col-lg-3">
              <b>Conta:</b> {{ iuguAccount.bank_account }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="row"
  *ngIf="
    iuguAccount.id == null && subscription && subscription.plano_ativo == true
  "
>
  <div class="col">
    <div class="card">
      <div class="card-content">
        <div class="card-body p-0">
          <ul class="list-group p-0">
            <li class="list-group-item">
              <div class="media">
                <div class="media-body">
                  <h5 class="mt-0">
                    Você não tem uma conta cadastrada para receber pagamentos.
                  </h5>
                  <span
                    >Cadastre a sua conta de recebimento para poder utilizar o
                    sistema de pagamentos O Personal Digital, onde você vai
                    poder receber o pagamento dos seus alunos referente às
                    mensalidades da consultoria, ou qualquer outro serviço ou
                    produto que você queira.
                  </span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="row"
  *ngIf="
    iuguAccount.id == null && subscription && subscription.plano_ativo == false
  "
>
  <div class="col">
    <div class="card">
      <div class="card-content">
        <div class="card-body p-0">
          <ul class="list-group p-0">
            <li class="list-group-item">
              <div class="media">
                <div class="media-body">
                  <h5 class="mt-0">
                    Essa função não está disponível na sua conta.
                  </h5>
                  <span
                    >Essa funcionalidade é apenas para assinantes. Para
                    habilitá-la
                    <a
                      class="text-danger text-bold-500"
                      href="https://app.opersonaldigital.com.br/pages/checkout"
                      target="_blank"
                      >clique aqui</a
                    >
                    e assine um plano.</span
                  >
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #newBank let-modal>
  <div class="card">
    <div class="card-header">
      <h4 class="card-title">Cadastro Nova Conta Bancária</h4>
    </div>
    <div class="card-content">
      <div class="card-body">
        <form #newContaForm="ngForm" class="editForm" novalidate>
          <br />
          <div class="form-row">
            <div class="col">
              <div class="form-group">
                <div class="controls">
                  <label for="banco">Banco</label>
                  <ng-select
                    [items]="bancos"
                    bindLabel="banco"
                    bindValue="codigo"
                    name="banco"
                    placeholder="Banco"
                    id="banco"
                    [(ngModel)]="account.banco"
                    #inputbanco="ngModel"
                    (change)="verifyBankAgency()"
                    required
                  ></ng-select>
                  <small
                    class="form-text text-muted danger"
                    *ngIf="inputbanco.errors?.required"
                    >Informe o banco</small
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col">
              <div class="form-group">
                <div class="controls">
                  <label for="agencia">Agência</label>
                  <input
                    type="text"
                    [dropSpecialCharacters]="false"
                    id="agencia"
                    class="form-control"
                    mask="0*"
                    [(ngModel)]="account.agencia"
                    name="agencia"
                    #inputagencia="ngModel"
                    placeholder="Agência"
                    required
                  />
                  <small
                    class="form-text text-muted danger"
                    *ngIf="inputagencia.errors?.required"
                    >Informe a agência</small
                  >
                </div>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <div class="controls">
                  <label for="digitoagencia">Dígito Agência</label>
                  <input
                    type="text"
                    [dropSpecialCharacters]="false"
                    id="digitoagencia"
                    class="form-control"
                    mask="0*"
                    [(ngModel)]="account.digito_agencia"
                    name="digitoagencia"
                    [disabled]="blockAgencyDigit"
                    #inputdigitoagencia="ngModel"
                    placeholder="Dígito Agência"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col">
              <div class="form-group">
                <div class="controls">
                  <label for="tipo_conta">Tipo de Conta</label>
                  <ng-select
                    [items]="tipoconta"
                    bindLabel="descricao"
                    bindValue="codigo"
                    name="tipo_conta"
                    placeholder="Tipo de Conta"
                    id="tipo_conta"
                    [(ngModel)]="account.tipo_conta"
                    #inputtipoconta="ngModel"
                    required
                  ></ng-select>
                  <small
                    class="form-text text-muted danger"
                    *ngIf="inputtipoconta.errors?.required"
                    >Informe o tipo de conta</small
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col">
              <div class="form-group">
                <div class="controls">
                  <label for="conta">Conta</label>
                  <input
                    type="text"
                    id="conta"
                    [dropSpecialCharacters]="false"
                    class="form-control"
                    mask="0*"
                    [(ngModel)]="account.conta"
                    name="conta"
                    #inputconta="ngModel"
                    placeholder="Conta"
                    required
                  />
                  <small
                    class="form-text text-muted danger"
                    *ngIf="inputconta.errors?.required"
                    >Informe a conta</small
                  >
                </div>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <div class="controls">
                  <label for="digitoconta">Dígito Conta</label>
                  <input
                    type="text"
                    id="digitoconta"
                    [dropSpecialCharacters]="false"
                    class="form-control"
                    mask="0*"
                    [(ngModel)]="account.digito_conta"
                    name="digitoconta"
                    #inputdigitoconta="ngModel"
                    placeholder="Dígito Conta"
                    [disabled]="blockAccountDigit"
                  />
                  <!-- <small class="form-text text-muted danger" *ngIf="inputdigitoconta.errors?.required">Informe o dígito
                    da conta</small> -->
                </div>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-12 text-center">
              <button
                type="button"
                class="btn btn-lg btn-danger mr-2"
                (click)="modal.close('Close click')"
              >
                Cancelar
              </button>
              <button
                type="button"
                class="btn btn-lg btn-warning"
                (click)="updateBank(modal)"
                [disabled]="!newContaForm.valid"
              >
                Atualizar Conta Bancária
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #resendDocuments let-modal>
  <div class="card">
    <div class="card-header">
      <h4 class="card-title">Reenvio de Documentos</h4>
    </div>
    <div class="card-content">
      <div class="card-body text-center cols">
        <div *ngIf="selfieImageURL" class="text-center my-2">
          <img [src]="selfieImageURL" style="max-width: 350px" />
        </div>

        <button
          class="btn btn-lg gradient-mint my-2 col-12"
          (click)="openModal(selfieModal)"
          *ngIf="checkDocumentRequested('SELFIE')"
        >
          Selfie
        </button>

        <hr *ngIf="checkDocumentRequested('IDENTIFICATION')" />

        <div *ngIf="identityFrontImageURL" class="text-center my-2">
          <img [src]="identityFrontImageURL" style="max-width: 350px" />
        </div>

        <button
          class="btn btn-lg gradient-mint col-12"
          (click)="openModal(identityModal)"
          *ngIf="checkDocumentRequested('IDENTIFICATION')"
        >
          Documento de Identidade
        </button>

        <hr *ngIf="checkDocumentRequested('ADDRESS_PROOF')" />

        <div *ngIf="identityBackImageURL" class="text-center my-2">
          <img [src]="identityBackImageURL" style="max-width: 350px" />
        </div>

        <button
          class="btn btn-lg gradient-mint my-2 col-12"
          (click)="openModal(addressProofModal)"
          *ngIf="checkDocumentRequested('ADDRESS_PROOF')"
        >
          Enviar Comprovante de Residência
        </button>

        <button
          type="button"
          class="btn btn-lg btn-warning my-2"
          [disabled]="!areAllDocumentsSubmitted()"
          (click)="resendIdentityVerification(modal)"
        >
          Reenviar Documentos
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #selfieModal let-modal>
  <div class="card">
    <div class="card-header">
      <h4 class="card-title">Tirar Selfie</h4>
    </div>
    <div class="card-content">
      <div class="card-body d-flex flex-column align-items-center">
        <aw-wizard #wizard [navBarLayout]="'large-empty-symbols'">
          <aw-wizard-step
            [stepTitle]="'Instruções'"
            [navigationSymbol]="{
              symbol: '&#xf1c6;',
              fontFamily: 'FontAwesome'
            }"
          >
            <h5>Instruções para a Selfie</h5>
            <ul>
              <li>Tire a foto em um local iluminado.</li>
              <li>Não utilize boné ou óculos, e não sorria.</li>
              <li>Posicione o documento de forma que fique legível.</li>
            </ul>
            <div class="col text-center">
              <p>Exemplo de como deve ser a foto:</p>
              <img
                src="https://files.wecodde.com/file/wecodde/instrucao_selfie.png"
                style="width: 200px; margin-left: auto; margin-right: auto"
              />
            </div>

            <button
              type="button"
              class="btn btn-block btn-lg gradient-mint my-2"
              (click)="takeSelfie(modal)"
            >
              <i class="fa fa-upload"></i> Escolher Arquivo
            </button>
          </aw-wizard-step>
        </aw-wizard>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #addressProofModal let-modal>
  <div class="card">
    <div class="card-header">
      <h4 class="card-title">Enviar Comprovante de Residência</h4>
    </div>
    <div class="card-content">
      <div class="card-body d-flex flex-column align-items-center">
        <aw-wizard #wizard [navBarLayout]="'large-empty-symbols'">
          <aw-wizard-step
            [stepTitle]="'Instruções'"
            [navigationSymbol]="{
              symbol: '&#xf1c6;',
              fontFamily: 'FontAwesome'
            }"
          >
            <h5>Instruções para o Comprovante de Residência</h5>

            <ul>
              <li>
                Comprovantes aceitos: Contas de energia, água, telefone, plano
                de saúde ou cartão de crédito.
              </li>
              <li>
                Enviar o documento por completo (evite fotos apenas do
                endereço).
              </li>
              <li>
                O comprovante deve ser no seu nome, não podendo ser em nome de
                terceiros.
              </li>
              <li>O comprovante não deve ter data superior a 60 dias.</li>
              <li>
                Certifique-se de que a foto não esteja tremida ou desfocada.
              </li>
            </ul>

            <div class="d-flex flex-column align-items-center">
              <p>Exemplo de como deve ser a imagem:</p>
              <img
                [src]="'assets/img/imagem-luz.webp'"
                alt="Exemplo de Comprovante 1"
                style="max-width: 250px; margin-bottom: 15px"
              />
            </div>

            <button
              type="button"
              class="btn btn-lg btn-block gradient-mint my-2"
              (click)="selecionarImagemVersoDocumento(modal)"
            >
              <i class="fa fa-upload"></i> Enviar Arquivo
            </button>
          </aw-wizard-step>
        </aw-wizard>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #identityModal let-modal>
  <div class="card">
    <div class="card-header">
      <h4 class="card-title">Enviar Documento de Identidade</h4>
    </div>
    <div class="card-content">
      <div class="card-body d-flex flex-column align-items-center">
        <aw-wizard #wizard [navBarLayout]="'large-empty-symbols'">
          <aw-wizard-step
            [stepTitle]="'Instruções'"
            [navigationSymbol]="{
              symbol: '&#xf1c6;',
              fontFamily: 'FontAwesome'
            }"
          >
            <h5>Instruções para Documento de Identidade</h5>

            <ul>
              <li>
                Envie o documento aberto no caso de CNH ou RG novo (não envie
                apenas um dos lados).
              </li>
              <li>
                No caso de documentos plastificados ou RG antigo, envie uma foto
                com os dois lados no mesmo arquivo.
              </li>
              <li>
                Certifique-se de que a imagem não esteja tremida ou escura.
              </li>
            </ul>

            <div class="d-flex flex-column align-items-center mt-4">
              <p>Exemplo de como deve ser a imagem:</p>
              <img
                [src]="'assets/img/imagem-doc.webp'"
                alt="Exemplo de Comprovante 1"
                style="max-width: 350px; margin-bottom: 15px"
              />
            </div>

            <button
              type="button"
              class="btn btn-lg btn-block gradient-mint my-2"
              (click)="selecionarImagemFrenteDocumento(modal)"
            >
              <i class="fa fa-upload"></i> Enviar Arquivo
            </button>
          </aw-wizard-step>
        </aw-wizard>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #newAccount let-modal>
  <div class="card">
    <div class="card-header">
      <h4 class="card-title">Cadastro Conta Recebimento</h4>
    </div>
    <div class="card-content">
      <div class="card-body">
        <aw-wizard #wizard [navBarLayout]="'large-empty-symbols'">
          <aw-wizard-step
            [stepTitle]="'Tipo de Conta'"
            [navigationSymbol]="{
              symbol: '&#xf05a;',
              fontFamily: 'FontAwesome'
            }"
          >
            <form #firstForm="ngForm" class="editForm" novalidate>
              <br />
              <div class="form-group">
                <label
                  >Você vai receber pagamentos como pessoa física ou pessoa
                  jurídica?</label
                >
                <div class="col p-0">
                  <ul class="list-unstyled mb-0">
                    <li
                      class="d-inline-block mr-2"
                      style="vertical-align: middle"
                    >
                      <div class="radio radio-sm">
                        <input
                          type="radio"
                          name="size-radio-1"
                          [value]="true"
                          id="size-sm"
                          (click)="switchTypeAccount(true)"
                          [(ngModel)]="isCPF"
                        />
                        <label for="size-sm">Pessoa Física</label>
                      </div>
                    </li>
                    <li class="d-inline-block" style="vertical-align: middle">
                      <div class="radio radio-sm">
                        <input
                          type="radio"
                          name="size-radio-1"
                          [value]="false"
                          id="size-default"
                          (click)="switchTypeAccount(false)"
                          [(ngModel)]="isCPF"
                          [disabled]="true"
                        />
                        <label for="size-default"
                          >Pessoa Jurídica
                          <span class="badge badge-primary ml-1"
                            >em breve</span
                          ></label
                        >
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="form-row mt-4">
                <div class="form-group mb-2">
                  <label for="grupoSelecionado">Vende Produtos Físicos?</label>
                  <ui-switch
                    checked
                    class="switchery"
                    placement="top"
                    ngbTooltip="Você comercializará produtos físicos?"
                    name="physical_products"
                    [(ngModel)]="account.physical_products"
                  ></ui-switch>
                </div>
              </div>
              <div class="form-row" *ngIf="isCPF">
                <div class="col-12">
                  <div class="form-group mb-2">
                    <label for="pf_cpf">CPF</label>
                    <input
                      type="text"
                      mask="000.000.000-00"
                      id="pf_cpf"
                      class="form-control"
                      name="pf_cpf"
                      [(ngModel)]="account.pf_cpf"
                      #inputCPF="ngModel"
                      required
                    />
                    <small
                      class="form-text text-muted danger"
                      *ngIf="inputCPF.errors?.required"
                      >Informe o seu CPF</small
                    >
                  </div>
                </div>
              </div>
              <div class="form-row" *ngIf="isCPF">
                <div class="col-12">
                  <div class="form-group mb-2">
                    <label for="pf_nome">Nome Completo</label>
                    <input
                      type="text"
                      id="pf_nome"
                      class="form-control"
                      name="pf_nome"
                      [(ngModel)]="account.pf_nome"
                      #inputNomeCompleto="ngModel"
                      required
                    />
                    <small
                      class="form-text text-muted danger"
                      *ngIf="inputNomeCompleto.errors?.required"
                      >Informe o seu nome completo</small
                    >
                  </div>
                </div>
              </div>
              <div class="form-row" *ngIf="!isCPF">
                <div class="col-12">
                  <div class="form-group mb-2">
                    <label for="pj_cnpj">CNPJ</label>
                    <input
                      type="text"
                      mask="00.000.000/0000-00"
                      id="pj_cnpj"
                      class="form-control"
                      name="pj_cnpj"
                      [(ngModel)]="account.pj_cnpj"
                      #inputCNPJ="ngModel"
                      required
                    />
                    <small
                      class="form-text text-muted danger"
                      *ngIf="inputCNPJ.errors?.required"
                      >Informe o seu CNPJ</small
                    >
                  </div>
                </div>
              </div>
              <div class="form-row" *ngIf="!isCPF">
                <div class="col-12">
                  <div class="form-group mb-2">
                    <label for="pj_nome">Nome da Empresa</label>
                    <input
                      type="text"
                      id="pj_nome"
                      class="form-control"
                      name="pj_nome"
                      [(ngModel)]="account.pj_nome"
                      #inputPJNOME="ngModel"
                      required
                    />
                    <small
                      class="form-text text-muted danger"
                      *ngIf="inputPJNOME.errors?.required"
                      >Informe o nome da empresa</small
                    >
                  </div>
                </div>
              </div>
              <div class="form-row" *ngIf="!isCPF">
                <div class="col-12">
                  <div class="form-group mb-2">
                    <label for="pj_cpf_responsavel">CPF Responsável</label>
                    <input
                      type="text"
                      mask="000.000.000-00"
                      id="pj_cpf_responsavel"
                      class="form-control"
                      name="pj_cpf_responsavel"
                      [(ngModel)]="account.pj_cpf_responsavel"
                      #inputCPFPJ="ngModel"
                      required
                    />
                    <small
                      class="form-text text-muted danger"
                      *ngIf="inputCPFPJ.errors?.required"
                      >Informe o CPF do responsável</small
                    >
                  </div>
                </div>
              </div>
              <div class="form-row" *ngIf="!isCPF">
                <div class="col-12">
                  <div class="form-group mb-2">
                    <label for="pj_nome_responsavel"
                      >Nome Completo Responsável</label
                    >
                    <input
                      type="text"
                      id="pj_nome_responsavel"
                      class="form-control"
                      name="pj_nome_responsavel"
                      [(ngModel)]="account.pj_nome_responsavel"
                      #inputNomeCompletoPJ="ngModel"
                      required
                    />
                    <small
                      class="form-text text-muted danger"
                      *ngIf="inputNomeCompletoPJ.errors?.required"
                      >Informe o nome completo do responsável</small
                    >
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col-12 text-center">
                  <button
                    type="button"
                    class="btn btn-lg btn-danger mr-2"
                    (click)="modal.close('Close click')"
                  >
                    Cancelar
                  </button>
                  <button
                    type="button"
                    class="btn btn-lg btn-success"
                    [disabled]="!firstForm.valid"
                    awNextStep
                  >
                    Próximo Passo
                  </button>
                </div>
              </div>
            </form>
          </aw-wizard-step>

          <aw-wizard-step
            [stepTitle]="'Endereço e Contato'"
            [navigationSymbol]="{
              symbol: '&#xf124;',
              fontFamily: 'FontAwesome'
            }"
          >
            <form #addressForm="ngForm" class="editForm" novalidate>
              <br />
              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="cep">CEP</label>
                      <input
                        type="text"
                        id="cep"
                        class="form-control"
                        [(ngModel)]="account.cep"
                        name="cep"
                        mask="00000-000"
                        placeholder="Cep"
                        (change)="consultaCep()"
                        #inputCep="ngModel"
                        required
                      />
                      <small
                        class="form-text text-muted danger"
                        *ngIf="inputCep.errors?.required"
                        >Informe o Cep</small
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="street">Rua/Avenida</label>
                      <input
                        type="text"
                        id="street"
                        class="form-control"
                        [(ngModel)]="account.endereco"
                        name="street"
                        #inputEndereco="ngModel"
                        placeholder="Rua/Avenida"
                        required
                      />
                      <small
                        class="form-text text-muted danger"
                        *ngIf="inputEndereco.errors?.required"
                        >Informe a rua</small
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="district">Bairro</label>
                      <input
                        type="text"
                        id="district"
                        class="form-control"
                        [(ngModel)]="account.bairro"
                        name="bairro"
                        placeholder="Bairro"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col-md-8 col-12">
                  <div class="form-group">
                    <div class="controls">
                      <label for="city">Cidade</label>
                      <input
                        type="text"
                        id="city"
                        class="form-control"
                        [(ngModel)]="account.cidade"
                        name="city"
                        #inputcidade="ngModel"
                        placeholder="Cidade"
                        required
                      />
                      <small
                        class="form-text text-muted danger"
                        *ngIf="inputcidade.errors?.required"
                        >Informe a cidade</small
                      >
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-12">
                  <div class="form-group">
                    <div class="controls">
                      <label for="state">Estado</label>
                      <ng-select
                        [items]="estados"
                        name="state"
                        placeholder="Estado"
                        id="state"
                        [(ngModel)]="account.estado"
                        #inputestado="ngModel"
                        required
                      ></ng-select>
                      <small
                        class="form-text text-muted danger"
                        *ngIf="inputestado.errors?.required"
                        >Informe o estado</small
                      >
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="phone">Telefone</label>
                      <ngx-intl-tel-input
                        [preferredCountries]="preferredCountries"
                        [enableAutoCountrySelect]="true"
                        [enablePlaceholder]="true"
                        [searchCountryFlag]="true"
                        [searchCountryField]="[
                          SearchCountryField.Iso2,
                          SearchCountryField.Name
                        ]"
                        [selectFirstCountry]="false"
                        [selectedCountryISO]="CountryISO.Brazil"
                        [maxLength]="15"
                        [phoneValidation]="true"
                        [separateDialCode]="separateDialCode"
                        [numberFormat]="PhoneNumberFormat.National"
                        name="phone"
                        searchCountryPlaceholder="Selecione o País"
                        [(ngModel)]="account.telefone"
                        required
                      >
                      </ngx-intl-tel-input>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col-12 text-center">
                  <button
                    type="button"
                    class="btn btn-lg btn-light mr-2"
                    awPreviousStep
                  >
                    Voltar
                  </button>
                  <button
                    type="button"
                    class="btn btn-lg btn-success"
                    [disabled]="!addressForm.valid"
                    awNextStep
                  >
                    Próximo Passo
                  </button>
                </div>
              </div>
            </form>
          </aw-wizard-step>

          <aw-wizard-step
            [stepTitle]="'Conta Bancária'"
            [navigationSymbol]="{
              symbol: '&#xf155;',
              fontFamily: 'FontAwesome'
            }"
          >
            <form #contaForm="ngForm" class="editForm" novalidate>
              <br />
              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="banco">Banco</label>
                      <ng-select
                        [items]="bancos"
                        bindLabel="banco"
                        bindValue="banco"
                        name="banco"
                        placeholder="Banco"
                        id="banco"
                        [(ngModel)]="account.banco"
                        #inputbanco="ngModel"
                        (change)="verifyBankAgency()"
                        required
                      ></ng-select>
                      <small
                        class="form-text text-muted danger"
                        *ngIf="inputbanco.errors?.required"
                        >Informe o banco</small
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="agencia">Agência</label>
                      <input
                        type="text"
                        [dropSpecialCharacters]="false"
                        id="agencia"
                        class="form-control"
                        mask="0*"
                        [(ngModel)]="account.agencia"
                        name="agencia"
                        #inputagencia="ngModel"
                        placeholder="Agência"
                        required
                      />
                      <small
                        class="form-text text-muted danger"
                        *ngIf="inputagencia.errors?.required"
                        >Informe a agência</small
                      >
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="digitoagencia">Dígito Agência</label>
                      <input
                        type="text"
                        [dropSpecialCharacters]="false"
                        id="digitoagencia"
                        class="form-control"
                        [(ngModel)]="account.digito_agencia"
                        name="digitoagencia"
                        [disabled]="blockAgencyDigit"
                        #inputdigitoagencia="ngModel"
                        placeholder="Dígito Agência"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="tipo_conta">Tipo de Conta</label>
                      <ng-select
                        [items]="tipoconta"
                        bindLabel="descricao"
                        bindValue="descricao"
                        name="tipo_conta"
                        placeholder="Tipo de Conta"
                        id="tipo_conta"
                        [(ngModel)]="account.tipo_conta"
                        #inputtipoconta="ngModel"
                        required
                      ></ng-select>
                      <small
                        class="form-text text-muted danger"
                        *ngIf="inputtipoconta.errors?.required"
                        >Informe o tipo de conta</small
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="conta">Conta</label>
                      <input
                        type="text"
                        id="conta"
                        [dropSpecialCharacters]="false"
                        class="form-control"
                        mask="0*"
                        [(ngModel)]="account.conta"
                        name="conta"
                        #inputconta="ngModel"
                        placeholder="Conta"
                        required
                      />
                      <small
                        class="form-text text-muted danger"
                        *ngIf="inputconta.errors?.required"
                        >Informe a conta</small
                      >
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="digitoconta">Dígito Conta</label>
                      <input
                        type="text"
                        id="digitoconta"
                        [dropSpecialCharacters]="false"
                        class="form-control"
                        mask="0*"
                        [(ngModel)]="account.digito_conta"
                        name="digitoconta"
                        #inputdigitoconta="ngModel"
                        placeholder="Dígito Conta"
                        [disabled]="blockAccountDigit"
                        required
                      />
                      <small
                        class="form-text text-muted danger"
                        *ngIf="inputdigitoconta.errors?.required"
                        >Informe o dígito da conta</small
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-2 mb-2">
                <div class="col-12">
                  <i class="ft ft-check-square"></i
                  ><small
                    >Ao cadastrar sua conta de recebimentos você estará de
                    acordo com os termos de uso e
                    <a
                      href="https://personaldigitalapp.gitbook.io/intermediacao-de-pagamentos/"
                      target="_blank"
                      >tarifas vigentes</a
                    ></small
                  >
                </div>
              </div>
              <div class="form-row">
                <div class="col-12 text-center">
                  <button
                    type="button"
                    class="btn btn-lg btn-light mr-2"
                    awPreviousStep
                  >
                    Voltar
                  </button>
                  <button
                    type="button"
                    class="btn btn-lg btn-success"
                    [disabled]="!contaForm.valid"
                    awNextStep
                  >
                    Próximo Passo
                  </button>
                </div>
              </div>
            </form>
          </aw-wizard-step>

          <aw-wizard-step
            [stepTitle]="'Envio de Documentos'"
            [navigationSymbol]="{
              symbol: '&#xf2c2;',
              fontFamily: 'FontAwesome'
            }"
          >
            <form #identificacaoForm="ngForm" class="editForm" novalidate>
              <br />
              <div class="form-row mb-4">
                <div class="col-12 col-lg-12 text-center mb-2">
                  <div *ngIf="selfieImageURL" class="text-center my-2">
                    <img [src]="selfieImageURL" style="max-width: 350px" />
                  </div>

                  <button
                    class="btn btn-lg gradient-mint my-2 col-12"
                    (click)="openModal(selfieModal)"
                  >
                    Selfie
                  </button>
                </div>
                <div class="col-12 col-lg-12 text-center mb-2">
                  <hr *ngIf="checkDocumentRequested('IDENTIFICATION')" />

                  <div *ngIf="identityFrontImageURL" class="text-center my-2">
                    <img
                      [src]="identityFrontImageURL"
                      style="max-width: 350px"
                    />
                  </div>

                  <button
                    class="btn btn-lg gradient-mint col-12"
                    (click)="openModal(identityModal)"
                  >
                    Documento de Identidade
                  </button>
                </div>

                <div class="form-row mb-4 justify-content-center">
                  <div class="col-12 col-lg-12 text-center mb-2">
                    <hr *ngIf="checkDocumentRequested('ADDRESS_PROOF')" />

                    <div *ngIf="identityBackImageURL" class="text-center my-2">
                      <img
                        [src]="identityBackImageURL"
                        style="max-width: 350px"
                      />
                    </div>

                    <button
                      class="btn btn-lg gradient-mint my-2 col-12"
                      (click)="openModal(addressProofModal)"
                    >
                      Enviar Comprovante de Residência
                    </button>
                  </div>
                </div>

                <div class="form-row">
                  <div class="col-12 text-center">
                    <button
                      type="button"
                      class="btn btn-lg btn-light mr-2"
                      awPreviousStep
                    >
                      Voltar
                    </button>
                    <button
                      type="button"
                      class="btn btn-lg btn-warning"
                      (click)="submitAccount(modal)"
                      [disabled]="
                        !identityFrontImage ||
                        !identityBackImage ||
                        !selfieImage
                      "
                      awNextStep
                    >
                      Cadastrar Conta
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </aw-wizard-step>
        </aw-wizard>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #contentVideo>
  <!-- <video controls style="width: 100%;">
    <source [src]="'https://files.wecodde.com/file/wecodde/01+-+Cadastro+de+dados+de+conta.mp4'" type="video/mp4">
  </video> -->
  <div class="embed-container">
    <iframe [src]="videoUrl" frameborder="0" allowfullscreen></iframe>
  </div>
</ng-template>
<ng-template #takepicture let-modal>
  <div class="card">
    <div class="card-header">
      <h4 class="card-title">Envio de Selfie</h4>
    </div>
    <div class="card-content">
      <div class="card-body">
        <aw-wizard #wizard [navBarLayout]="'large-empty-symbols'">
          <aw-wizard-step
            [stepTitle]="'Instruções'"
            [navigationSymbol]="{
              symbol: '&#xf05a;',
              fontFamily: 'FontAwesome'
            }"
          >
            <h4>Dicas para sua foto ser aprovada:</h4>
            <ul style="color: red">
              <li>Tire a foto em um local iluminado</li>
              <li>Não utilize boné ou óculos, não sorria</li>
              <li>Posicione o documento de forma que fique legível</li>
            </ul>

            <div class="col text-center">
              <p>Exemplo de como deve ser a foto:</p>
              <img
                src="https://files.wecodde.com/file/wecodde/instrucao_selfie.png"
                style="width: 200px; margin-left: auto; margin-right: auto"
              />
            </div>

            <div class="form-row mt-2">
              <div class="col-12 text-center">
                <button type="button" class="btn btn-lg btn-success" awNextStep>
                  Tirar Selfie
                </button>
              </div>
            </div>
          </aw-wizard-step>

          <aw-wizard-step
            [stepTitle]="'Envio da Imagem'"
            [navigationSymbol]="{
              symbol: '&#xf05a;',
              fontFamily: 'FontAwesome'
            }"
          >
            <div class="row mb-2" id="print-section">
              <div class="col text-center">
                <webcam
                  [height]="600"
                  [width]="400"
                  [trigger]="triggerObservable"
                  (imageCapture)="handleImage($event, modal)"
                  [imageQuality]="1"
                  [allowCameraSwitch]="true"
                >
                </webcam>
                <button
                  type="button"
                  class="btn btn-lg btn-primary mb-2 mb-md-0 btn-block"
                  (click)="triggerSnapshot()"
                >
                  Tirar Foto
                </button>
              </div>
            </div>
          </aw-wizard-step>
        </aw-wizard>
      </div>
    </div>
  </div>
</ng-template>
<ngx-spinner></ngx-spinner>
