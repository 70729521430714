import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class CpfValidatorService {
  constructor() {}

  isValidCPF(cpf: string): boolean {
    if (typeof cpf !== "string") return false;
  
    // Remove caracteres não numéricos
    const cleanCPF = cpf.replace(/\D/g, "");
  
    // Validações iniciais: tamanho e sequência repetida
    if (cleanCPF.length !== 11 || /^(\d)\1+$/.test(cleanCPF)) {
      return false;
    }
  
    // Função para calcular um dígito verificador
    const calculateDigit = (cpf: string, length: number): number => {
      let sum = 0;
      for (let i = 0; i < length; i++) {
        sum += parseInt(cpf.charAt(i)) * (length + 1 - i);
      }
      const remainder = (sum * 10) % 11;
      return remainder === 10 ? 0 : remainder;
    };
  
    // Calcula os dois dígitos verificadores
    const firstDigit = calculateDigit(cleanCPF, 9);
    const secondDigit = calculateDigit(cleanCPF, 10);
  
    // Verifica se os dígitos calculados coincidem com os fornecidos
    return (
      firstDigit === parseInt(cleanCPF.charAt(9)) &&
      secondDigit === parseInt(cleanCPF.charAt(10))
    );
  }
}
