import { Component, OnInit, HostListener } from "@angular/core";
import Api from "app/helpers/api";
import App from "app/helpers/app";
import { ActivatedRoute } from "@angular/router";
import { Seller } from "./seller.types";
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: "app-personal-marketplace-layout",
  templateUrl: "./personal-marketplace-layout.component.html",
  styleUrls: ["./personal-marketplace-layout.component.scss"],
})
export class PersonalMarketplaceLayoutComponent implements OnInit {
  seller: Seller;
  slug: string;
  userUrl: any;
  selectedColor: string;
  apiCall = this.api.new().silent();
  isMobile: boolean;
  maxChars: number = 0;
  isExpanded: boolean = false;
  displayText: string = "";

  constructor(
    private api: Api,
    private app: App,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute
  ) {
    this.route.paramMap.subscribe((params) => {
      this.slug = params.get("slug");
    });
  }

  ngOnInit(): void {
    this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    this.sellerInfo();
    this.checkWindowSize();
  }

  sellerInfo() {
    this.apiCall.get(`/seller-store/slug/${this.slug}`).subscribe(
      (data) => {
        this.seller = data.return;
        this.selectedColor = this.seller.baseColor
          ? this.seller.baseColor
          : "#F77E17";
        console.log("user", this.seller);
        if (this.seller) {
          this.userUrl = this.seller.User;
        }
        this.spinner.hide();
      },
      (error) => {
        console.log("Erro ao obter informações da loja:", error);
      }
    );
  }

  openInstagram(username: string): void {
    if (username && username.startsWith("http")) {
      window.open(username, "_blank");
    } else {
      const url = `https://instagram.com/${username}`;
      window.open(url, "_blank");
    }
  }

  openWhatsApp(phone: string): void {
    if (phone && phone.startsWith("http")) {
      window.open(phone, "_blank");
    } else {
      const formattedPhone = phone.replace(/\D/g, "");
      const url = `https://wa.me/${formattedPhone}`;
      window.open(url, "_blank");
    }
  }

  openEmail(email: string): void {
    if (email && email.startsWith("http")) {
      window.open(email, "_blank");
    } else {
      const url = `https://mail.google.com/mail/?view=cm&fs=1&to=${email}`;
      window.open(url, "_blank");
    }
  }

  openOther(url: string): void {
    if (url && url.startsWith("http")) {
      window.open(url, "_blank");
    } else {
      console.error("URL inválido:", url);
    }
  }

  @HostListener("window:resize", ["$event"])
  onResize(event: any): void {
    this.checkWindowSize();
    this.updateDescription();
  }

  checkWindowSize(): void {
    this.isMobile = window.innerWidth <= 768;
  }

  toggleExpand(): void {
    this.isExpanded = !this.isExpanded;
    this.updateDescription();
  }
  updateDescription(): void {
    if (this.seller.description.length > this.maxChars) {
      this.displayText = this.isExpanded
        ? this.seller.description
        : this.seller.description.slice(0, this.maxChars);
    } else {
      this.displayText = this.seller.description;
    }
  }
}
